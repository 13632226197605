import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByWeaveComponets from './EMSalesReportByWeaveComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByWeaveScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Weave"} searchTitre ={'Search by Weave'} itemForChart={"sku__config_info__weave"} categoryFilttering={'sku__config_info__weave'}>

<EMSalesReportByWeaveComponets />

</ReportFunction>

    );
};

export default EMSalesReportByWeaveScreen;