import React from 'react'
import CustomerBodyComponents from './CustomerBodyComponents'

function CustomerHomeScreen() {
    return (
        <CustomerBodyComponents>
            <h1 className='p-2'>
                Wellcome To Customer Page

            </h1>
            <h2 className='py-4'>

                Here you can achieve your dream
            </h2>

            <h1>
                Welcome to Customer Page
</h1>
        </CustomerBodyComponents>
    )
}

export default CustomerHomeScreen