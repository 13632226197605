import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByVendorNameComponets from './EMSalesReportByVendorNameComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByVendorNameScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Vendor Name"} searchTitre ={'Search by Vendor Name'} itemForChart={"sku__config_info__vendor_name__name"} categoryFilttering={"sku__config_info__vendor_name__name"}>

<EMSalesReportByVendorNameComponets />

</ReportFunction>

    );
};

export default EMSalesReportByVendorNameScreen;