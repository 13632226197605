import React from 'react'
import { Card } from 'react-bootstrap'
// import Rating from './Rating'
import { Link } from 'react-router-dom';

function Product({ product }) {
  return (
    <div>
      <Card className='p-3 my-3 rounded' style={{
        height: '350x',
        alignItems:'center'
      }}>
         
        <Link to={`/product/${product.id}`}>
          {/* <Card.Img src={product.image} className='card-img-top' /> */}
          <Card.Img
          src={require("./logo.png")}
          //  src={product.photo} 
           className='card-img-top' style={{
            height:'300px'
          }}/>
        </Link>
        <Card.Body>
          <Link to={`/product/${product.id}`}>
            <br />

            {product.manufacturer}
            <Card.Title as="h5">
              <strong style={{color: "black" }}>{product.name}</strong>
            </Card.Title>
            {/* <br />
            <Card.Text as="div" className='my-3'>
            {product.sku}
            <br />
            {product.upc} */}
              {/* {product.rating} from {product.numReviews} Reviews */}
              {/* <Rating value={product.rating} text={`مشاهده شده: ${product.numReviews}`} color={"#F8E825"} /> */}
            {/* </Card.Text> */}

          </Link>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Product
