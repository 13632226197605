import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByCountryComponets from './EMSalesReportByCountryComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByCountryScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Country"} searchTitre ={'Search by Country'} itemForChart={"sku__config_info__country_of_manufacture"} categoryFilttering={'sku__config_info__country_of_manufacture'}>

<EMSalesReportByCountryComponets />

</ReportFunction>

    );
};

export default EMSalesReportByCountryScreen;