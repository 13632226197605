import React, { useEffect, useState } from 'react'
import EmployeeBodyComponents from './EmployeeBodyComponents'
import { Link } from 'react-router-dom'
import { Card, Col, ListGroup, Row, Table } from 'react-bootstrap'
import { GET_LINK_HOME_CARD_EMPLOYE, MEDIA_LINK } from '../mainesrc/AllLinkConst';
import axios from 'axios';
import Message from '../mainesrc/Message';
import styled from 'styled-components';

// import EmployeeBodyComponents from '../EmployeeBodyComponents'
// import React, { useState, useEffect } from 'react';
// import CheckoutSteps from '../../mainesrc/CheckoutSteps';
// import axios from 'axios';
// import { Button, Col, InputGroup, Row, Table } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import Pagination from './Pagination'; // مسیر صحیح فایل Pagination را وارد کنید
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement);
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);




// استایل‌های کاستوم با استفاده از styled-components
const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
     background-color: #f8f9fa;

  
`;

const TableRow = styled.div`
  display: flex;
  border-bottom: 1px solid #dee2e6;
  &:last-child {
    border-bottom: none;
     background-color: #f8f9fa;


  }
`;

const TableHeader = styled(TableRow)`
  font-weight: bold;
  background-color: #f8f9fa;

`;

const TableCell = styled.div`
  flex: ${props => props.flex || 1};
  padding: 0.75rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;






function EmployeeHomeScreen() {
  const [homeCard, setHomeCard] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);

  const itemForChart = ['item1', 'item2', 'item3', 'item4', 'item5', 'item6', 'item7', 'item8', 'item9', 'item10', 'item11', 'item12', 'item13'];

  // اصلاح شده: استفاده از item به جای item[itemForChart]
  const labels = itemForChart.map(item => item);

  const verticalBarData = {
    labels,
    datasets: [
      {
        label: 'Total Sales',
        data: [1, 10, 12, 20,30,40,50,60,70,80,90,10,13],
        backgroundColor: 'rgba(127, 255, 212, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Average Sales',
        data: [5, 6, 4, 7,5,9,77,41,25,10,11,56,30],
        type: 'line',
        fill: false,
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 2,
      },
      // {
      //     label: 'Total Added Products',
      //     data: totalAddedProducts,
      //     type:"line",
      //     backgroundColor: 'rgba(153, 102, 255, 0.6)',
      //     borderColor: 'rgba(153, 102, 255, 1)',
      //     borderWidth: 1,
      // },

    ],
  };







  const options_verticalBarData = {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false, // این گزینه را اضافه کنید
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 14, // اندازه فونت لژند
            family: 'Arial', // نوع فونت لژند
            color: '#333', // رنگ فونت لژند
          },
        },
      },
      title: {
        display: true,
        text: 'Total Sell',
        font: {
          size: 18, // اندازه فونت عنوان
          family: 'Arial', // نوع فونت عنوان
          weight: 'bold', // وزن فونت عنوان
        },
        color: '#333', // رنگ عنوان
      },
    },
    elements: {
      bar: {
        borderRadius: 10, // شعاع گوشه‌ها
        borderWidth: 2, // عرض حاشیه
        borderColor: 'rgba(0, 0, 0, 0.1)', // رنگ حاشیه
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          color: 'rgba(200, 200, 200, 0.5)', // رنگ خطوط شبکه
        },
      },
      y: {
        grid: {
          color: 'rgba(200, 200, 200, 0.5)', // رنگ خطوط شبکه
        },
      },
    },
    layout: {
      padding: {
        left: 0, // padding را به صفر تغییر دهید
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };
  useEffect(() => {
    const fetchCompanys = async () => {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = userInfo.token;

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Fix the typo 'Bearera' to 'Bearer'
          }
        };

        const { data } = await axios.get(GET_LINK_HOME_CARD_EMPLOYE, config);
        setHomeCard(data);
        console.log(homeCard)
      } catch (error) {
        const errorMessage = error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message;

        setErrorMessage(errorMessage);
      }
    };

    fetchCompanys();

  }, []);
  return (
    <EmployeeBodyComponents>
      {/* {errorMessage && <Message variant='danger' text={errorMessage}/>} */}
      {errorMessage && errorMessage.length > 0 && <Message variant='danger' text={errorMessage} />}





      <div className="m-1">
        <Row className="m-1">
          <Col lg={4} sm={12}>
            <Card className="text-center" bg={'primary'} text={"light"}>
              <Card.Body>
                <Card.Title>Total Sell</Card.Title>
                <Card.Text style={{ backgroundColor: 'rgba(127, 255, 212, 0.6)', color: 'black', borderRadius: "10px", padding: '5px', fontSize: '1.5rem', fontWeight: 'bold' }} >
                  {/* اطلاعات مربوط به Total Sell */}
                  $1000
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} sm={12}>
            <Card className="text-center" bg={'light'} text={"light"}>
              <Card.Body>
                <Card.Title>Total Profit</Card.Title>
                <Card.Text style={{ backgroundColor: 'rgba(127, 255, 212, 0.6)', color: 'black', borderRadius: "10px", padding: '5px', fontSize: '1.5rem', fontWeight: 'bold' }}>
                  {/* اطلاعات مربوط به Total Profit */}
                  $500
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} sm={12}>
            <Card className="text-center" bg={'danger'} text={"light"}>
              <Card.Body>
                <Card.Title>Total Cost</Card.Title>
                <Card.Text style={{ backgroundColor: 'rgba(127, 255, 212, 0.6)', color: 'black', borderRadius: "10px", padding: '5px', fontSize: '1.5rem', fontWeight: 'bold' }}>
                  {/* اطلاعات مربوط به Total Cost */}
                  $500
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {/* 'primary',
        'secondary',
        'light',
        'danger',
        'warning',
        'info',
        'light',
        'dark', */}

          <div className='p-3'>

          </div>

          <Row>
            <Col sm={12} lg={6}>
            <TableContainer>
        <TableHeader>
          <TableCell flex={0.5}>#</TableCell>
          <TableCell flex={2}>Vendor</TableCell>
          <TableCell flex={1}>Profit</TableCell>
          <TableCell flex={1}>Sell</TableCell>
          <TableCell flex={1}>Cost</TableCell>
        </TableHeader>
        <TableRow>
          <TableCell flex={0.5}>1</TableCell>
          <TableCell flex={2}>Loloi</TableCell>
          <TableCell flex={1}>250</TableCell>
          <TableCell flex={1}>100</TableCell>
          <TableCell flex={1}>200</TableCell>
        </TableRow>
        <TableRow>
          <TableCell flex={0.5}>1</TableCell>
          <TableCell flex={2}>Loloi</TableCell>
          <TableCell flex={1}>250</TableCell>
          <TableCell flex={1}>100</TableCell>
          <TableCell flex={1}>200</TableCell>
        </TableRow>
        <TableRow>
          <TableCell flex={0.5}>1</TableCell>
          <TableCell flex={2}>Loloi</TableCell>
          <TableCell flex={1}>250</TableCell>
          <TableCell flex={1}>100</TableCell>
          <TableCell flex={1}>200</TableCell>
        </TableRow>
        <TableRow>
          <TableCell flex={0.5}>1</TableCell>
          <TableCell flex={2}>Loloi</TableCell>
          <TableCell flex={1}>250</TableCell>
          <TableCell flex={1}>100</TableCell>
          <TableCell flex={1}>200</TableCell>
        </TableRow>
        <TableRow>
          <TableCell flex={0.5}>1</TableCell>
          <TableCell flex={2}>Loloi</TableCell>
          <TableCell flex={1}>250</TableCell>
          <TableCell flex={1}>100</TableCell>
          <TableCell flex={1}>200</TableCell>
        </TableRow>
        <TableRow>
          <TableCell flex={0.5}>1</TableCell>
          <TableCell flex={2}>Loloi</TableCell>
          <TableCell flex={1}>250</TableCell>
          <TableCell flex={1}>100</TableCell>
          <TableCell flex={1}>200</TableCell>
        </TableRow>
        <TableRow>
          <TableCell flex={0.5}>1</TableCell>
          <TableCell flex={2}>Loloi</TableCell>
          <TableCell flex={1}>250</TableCell>
          <TableCell flex={1}>100</TableCell>
          <TableCell flex={1}>200</TableCell>
        </TableRow>
        <TableRow>
          <TableCell flex={0.5}>1</TableCell>
          <TableCell flex={2}>Loloi</TableCell>
          <TableCell flex={1}>250</TableCell>
          <TableCell flex={1}>100</TableCell>
          <TableCell flex={1}>200</TableCell>
        </TableRow>
        <TableRow>
          <TableCell flex={0.5}>1</TableCell>
          <TableCell flex={2}>Loloi</TableCell>
          <TableCell flex={1}>250</TableCell>
          <TableCell flex={1}>100</TableCell>
          <TableCell flex={1}>200</TableCell>
        </TableRow>
        {/* می‌توانید ردیف‌های بیشتری اضافه کنید */}
      </TableContainer>

            </Col>
            <Col sm={12} lg={6} style={{ backgroundColor: '#f9f9f9', padding: '1px', borderRadius: '10px' }}>



              <Bar key="vertical-bar" data={verticalBarData} options={
                //     {
                //     responsive: true,

                //     plugins: {
                //         legend: { position: 'top' },
                //         title: { display: true, text: 'Total Sales by Color (Vertical Bar Chart)' },
                //     },
                // }
                options_verticalBarData
              } />



            </Col>
            <div className='p-2'></div>
            <Col sm={12} lg={6} style={{ backgroundColor: '#f9f9f9', padding: '1px', borderRadius: '10px' }}>



              <Bar key="vertical-bar" data={verticalBarData} options={
                //     {
                //     responsive: true,

                //     plugins: {
                //         legend: { position: 'top' },
                //         title: { display: true, text: 'Total Sales by Color (Vertical Bar Chart)' },
                //     },
                // }
                options_verticalBarData
              } />



            </Col>
            <Col sm={12} lg={6}>

              <Row className='g-2'>

                <Col lg={6} sm={12}>
                  <Card className="text-center" bg={'light'}>
                    <Card.Body>
                      <Card.Title>Loloi</Card.Title>
                      <ListGroup className="flush">
                        <ListGroup.Item><strong>Sell: 500 $ </strong></ListGroup.Item>
                        <ListGroup.Item><strong>Profit: 250$ </strong></ListGroup.Item>
                        <ListGroup.Item><strong>Cost: 250$ </strong></ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6} sm={12}>
                  <Card className="text-center" bg={'light'}>
                    <Card.Body>
                      <Card.Title>Trance Oseac</Card.Title>
                      <ListGroup className="flush">
                        <ListGroup.Item><strong>Sell: 500 $ </strong></ListGroup.Item>
                        <ListGroup.Item><strong>Profit: 250$ </strong></ListGroup.Item>
                        <ListGroup.Item><strong>Cost: 250$ </strong></ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>

                <Col lg={6} sm={12}>
                  <Card className="text-center" bg={'light'}>
                    <Card.Body>
                      <Card.Title>Amer</Card.Title>
                      <ListGroup className="flush">
                        <ListGroup.Item><strong>Sell: 500 $ </strong></ListGroup.Item>
                        <ListGroup.Item><strong>Profit: 250$ </strong></ListGroup.Item>
                        <ListGroup.Item><strong>Cost: 250$ </strong></ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6} sm={12}>
                  <Card className="text-center" bg={'light'}>
                    <Card.Body>
                      <Card.Title>Amer</Card.Title>
                      <ListGroup className="flush">
                        <ListGroup.Item><strong>Sell: 500 $ </strong></ListGroup.Item>
                        <ListGroup.Item><strong>Profit: 250$ </strong></ListGroup.Item>
                        <ListGroup.Item><strong>Cost: 250$ </strong></ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>

            </Col>





          </Row>



        </Row>




        <Row className="g-2">

          <Col lg={3} sm={12}>

            <Card className="text-center" bg={'light'}>
              <Card.Body>
                <Card.Title>Loloi</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Sell: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Profit: 250$ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Cost: 250$ </strong></ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>

          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'light'}>
              <Card.Body>
                <Card.Title>Trance Oseac</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Sell: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Profit: 250$ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Cost: 250$ </strong></ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'light'}>
              <Card.Body>
                <Card.Title>Amer</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Sell: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Profit: 250$ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Cost: 250$ </strong></ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={12}>



            <Card className="text-center" bg={'light'}>
              <Card.Body>
                <Card.Title>Tourisan</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Sell: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Profit: 250$ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Cost: 250$ </strong></ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>



          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'light'}>
              <Card.Body>
                <Card.Title>Loloi</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Sell: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Profit: 250$ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Cost: 250$ </strong></ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} lg={9} style={{ backgroundColor: '#f9f9f9', padding: '1px', borderRadius: '10px' }}>








            <Bar key="vertical-bar" data={verticalBarData} options={
              //     {
              //     responsive: true,

              //     plugins: {
              //         legend: { position: 'top' },
              //         title: { display: true, text: 'Total Sales by Color (Vertical Bar Chart)' },
              //     },
              // }
              options_verticalBarData
            } />











          </Col>


        </Row>

        <div className='p-2'>

        </div>
        <Row className="g-3">
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'info'}>
              <Card.Body>
                <Card.Title>Color</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Item 1: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 2: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 3: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 4: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 5: 500 $ </strong></ListGroup.Item>

                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'info'}>
              <Card.Body>
                <Card.Title>Category</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Item 1: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 2: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 3: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 4: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 5: 500 $ </strong></ListGroup.Item>

                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'info'}>
              <Card.Body>
                <Card.Title>Material</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Item 1: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 2: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 3: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 4: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 5: 500 $ </strong></ListGroup.Item>

                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'info'}>
              <Card.Body>
                <Card.Title>Color</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Item 1: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 2: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 3: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 4: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 5: 500 $ </strong></ListGroup.Item>

                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'info'}>
              <Card.Body>
                <Card.Title>Color</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Item 1: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 2: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 3: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 4: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 5: 500 $ </strong></ListGroup.Item>

                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'info'}>
              <Card.Body>
                <Card.Title>Color</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Item 1: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 2: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 3: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 4: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 5: 500 $ </strong></ListGroup.Item>

                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'info'}>
              <Card.Body>
                <Card.Title>Color</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Item 1: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 2: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 3: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 4: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 5: 500 $ </strong></ListGroup.Item>

                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} sm={12}>
            <Card className="text-center" bg={'info'}>
              <Card.Body>
                <Card.Title>Color</Card.Title>
                <ListGroup className="flush">
                  <ListGroup.Item><strong>Item 1: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 2: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 3: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 4: 500 $ </strong></ListGroup.Item>
                  <ListGroup.Item><strong>Item 5: 500 $ </strong></ListGroup.Item>

                </ListGroup>
              </Card.Body>
            </Card>
          </Col>





        </Row>



      </div>




    </EmployeeBodyComponents>
  )
}

export default EmployeeHomeScreen





