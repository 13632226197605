import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByPriceRangeComponets from './EMSalesReportByPriceRangeComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByPriceRangeScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By PriceRange"} searchTitre ={'search by Pricerange as (2121-2222)'} itemForChart={"sku__pricerange"} categoryFilttering={'sku__priceRange'}>

<EMSalesReportByPriceRangeComponets />

</ReportFunction>

    );
};

export default EMSalesReportByPriceRangeScreen;