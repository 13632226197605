// import EmVendorBodyComponents from './EmVendorBodyComponents'
import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Form, Row } from 'react-bootstrap';
// import { GET_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE, POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP1 } from '../../mainesrc/AllLinkConst';
// import CheckoutSteps from '../../mainesrc/CheckoutSteps';
// import LoaderComponents from '../../mainesrc/LoaderComponents';
// import Message from '../../mainesrc/Message';




function AddToDataBaseScreen() {
    // const { pk } = useParams();
    // const [workSpaceDetaile, setWorkSpaceDetaile] = useState([]);
    // const [errorMessage, setErrorMessage] = useState([]);
    // const [loading, setLoading] = useState(false);
 
    // const [step2, setStep2] = useState(false)
    // const [step3, setStep3] = useState(false)
    // const [step4, setStep4] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    // const [documetId, setDocumetId] = useState();
    // const [vendorName, setVendorName] = useState();

    const POST_LINK_EMPLOYEE_CLEANDATA_TO_ADD_DATA = 'http://127.0.0.1:8000/api/v1/employee/vendor/add_data_to_Database';

    // -----------  Handel Step1

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handelStep1 = async (e) => {
        e.preventDefault();
        // setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = userInfo.token;

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
        // formData.append('selectedRadio', vendorName);
        // formData.append('document_id', documetId);



        try {
            const response = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_TO_ADD_DATA, formData, config);
            const jsonData = response.data.data;
            // setLoading(false)
            // setStep1(false)
            // setStep2(true)

            if (jsonData === 'ok') {
                // setLoading(false);
                // navigate('/vendor/step2VendorLink');
            }
        } catch (error) {
            // setLoading(false);
            const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
            // setErrorMessage(errorMessage);
            console.error('Error uploading file:', error);
            console.log(errorMessage)
        }
    };
    return (
        <>
<main>

            <Form 
                onSubmit={handelStep1}
            >
                <h4>
                    Import the vendor file to cleane
                </h4>
                <Form.Group controlId="formFile" >
                    <Row className='g-2'>
                        <Col xl={3} xxl={2} >
                            <Button variant="primary" type="submit" className='w-100'  > Upload Vendor File</Button>
                        </Col>
                        <Col xl={9} xxl={10}>
                            <Form.Control type="file"
                                onChange={handleFileChange}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
                </main>





        </>
    )
}

export default AddToDataBaseScreen







