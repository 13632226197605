import React, { useEffect, useState } from 'react'
import EmployeeBodyComponents from '../EmployeeBodyComponents'
import axios from 'axios';
import { GET_LINK_EMPLOYEE_CLEANDATA_VENDOR_INFO,MEDIA_LINK } from '../../mainesrc/AllLinkConst';
import { Button, Card, Col, Container, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CheckoutSteps from '../../mainesrc/CheckoutSteps';
// import EmployeeBodyComponents from '../EmployeeBodyComponents';

function EmVendorCleanDataScreen() {
  const [homeCard, setHomeCard] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);



  useEffect(() => {
    const fetchCompanys = async () => {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = userInfo.token;

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Fix the typo 'Bearera' to 'Bearer'
          }
        };

        const { data } = await axios.get(GET_LINK_EMPLOYEE_CLEANDATA_VENDOR_INFO, config);
        setHomeCard(data);
        console.log(data)
        

      } catch (error) {
        const errorMessage = error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message;

        setErrorMessage(errorMessage);
      }
    };

    fetchCompanys();

  }, []);
  return (
    <EmployeeBodyComponents>
<Container fluid>

<CheckoutSteps step1="Vendor" step1Link='/employee/vendor' />
      <Row>
        {homeCard.map((title, index) => (
         <Col lg = {6} className = 'p-1' key={index}>

          <Link to={`/employee/vendor/cleandata/${title.name}`} style={{
            textDecoration: 'none'
          }}>
            <Card style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#FAF0E6' }} id ="cart-clean-data-vendor-employee">
              <Card.Header>
                <Nav variant="pills" defaultActiveKey="#first">
                  <Card.Img style={{ width: '200px',height:'200px', alignContent: 'center' }} src={`${MEDIA_LINK}${title.vendor_name.photo}`} />
                </Nav>
              </Card.Header>
              <Card.Body>
                <Card.Title>{title.vendor_name.brand_name}  </Card.Title>
                <Card.Title>{title.name}  </Card.Title>
                <Card.Title>{title.vendor_name.fullName}  </Card.Title>
                <Card.Text style={{ fontSize: '0.8rem', color: 'blue' }}>
                
              {title.vendor_name.description}  
                
                  <Row>
                    <Col>TOTAL PRODUCT: {title.total_product}</Col>
                    {/* <Col>Active Sku: 4000</Col> */}
                  </Row>
                  {/* <Row>
                    <Col>Open Dacument: 200</Col>
                    <Col>Active Sku: 4000</Col>
                  </Row>
                  <Row>
                    <Col>All Sku: 15000</Col>
                    <Col>Active Sku: 4000</Col>
                  </Row> */}
                </Card.Text>

              </Card.Body>
            </Card>
          </Link>
       </Col>

      ))}
    </Row>


{/* <EmVendorCleanDataDocScreen/> */}
</Container>

    </EmployeeBodyComponents >
  )
}

export default EmVendorCleanDataScreen