import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByDesignIdComponets from './EMSalesReportByDesignIdComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByDesignIdScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Design ID"} searchTitre ={'Search by Design ID'} itemForChart={"sku__config_info__design_id"} categoryFilttering={'sku__config_info__design_id'}>

<EMSalesReportByDesignIdComponets />

</ReportFunction>

    );
};

export default EMSalesReportByDesignIdScreen;