import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function CustomerBodyComponents({ children }) {
    return (
        <>

            <header>

            <Navbar expand="lg" bg="primary" variant="dark">
                    <Container>
                        <Navbar.Brand>
                            <Nav.Link as={Link} to="/" >
                                Home
                            </Nav.Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link as={Link} to="/login" >Login</Nav.Link>
                               
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header >

            <main className='pt-2'>
                <Container>


                    {children}


                </Container>
            </main>

        </>
    )
}

export default CustomerBodyComponents