import React from 'react'



function EMReportComponets({salesData}) {

  return (
  
<>


    <thead>
        <tr>
            <th>SKU</th>
            <th>Name</th>
            <th>Total Sales</th>
            <th>Total Added Products</th>
            <th>Average Sales</th>
            <th>Variance Sales</th>
            <th>Max Sales</th>
            <th>Min Sales</th>
            <th>Count Products</th>
            <th>UPC</th>
            <th>category</th>
            <th>material</th>
            <th>size</th>
            <th>free_rugpad</th>
            <th>shape</th>
            <th>color</th>
            <th>color_filter</th>
            <th>active</th>

            <th>Real Time Price ID</th>
            <th>Real Time Price </th>
            <th>Real Time Profit</th>


            <th>Vendor Inventory</th>
            <th>Vendor Sales</th>
            <th>Add Product</th>
            <th>Create Date</th>
            <th>Config ID Code</th>
            <th>Price Range</th>
            <th>Last Price</th>
            <th>Last Price ID</th>
            <th>Last Profit</th>
            <th>design_id</th>
            <th>Config name</th>
            <th>Config Sku</th>
            <th>collection_name</th>
            <th>manufacturer</th>
            <th>weave</th>
            <th>country_of_manufacture</th>
            <th>material</th>
            <th>vendor_name name</th>
            <th>fullName</th>
            <th>shippingh</th>
            <th>shippingl</th>
            <th>shippingw</th>
            <th>shippingmethod</th>
            <th>weight</th>

        </tr>
    </thead>
    <tbody>
        {Array.isArray(salesData) && salesData.length > 0 ? (
            salesData.map((item) => (
                <tr key={item.sku}>
                    <td>{item.sku}</td>
                    <td>{item.name}</td>
                    <td>{item.total_sales}</td>
                    <td>{item.total_added_products}</td>
                    <td>{item.average_sales}</td>
                    <td>{item.variance_sales}</td>
                    <td>{item.max_sales}</td>
                    <td>{item.min_sales}</td>
                    <td>{item.count_products}</td>
                    <td>{item.upc}</td>
                    <td>{item.category}</td>
                    <td>{item.material}</td>
                    <td>{item.size}</td>
                    <td>{item.free_rugpad}</td>
                    <td>{item.shape}</td>
                    <td>{item.color}</td>
                    <td>{item.color_filter}</td>
                    <td>{item.active ? 'Yes' : 'No'}</td>
                    <td>{item.real_time_price_id}</td>
                    <td>{item.real_time_price}</td>
                    <td>{item.real_time_profit}</td>
                    <td>{item.vendor_inventory}</td>
                    <td>{item.vendor_sales}</td>
                    <td>{item.add_product}</td>
                    <td>{new Date(item.crearte_in_db).toLocaleString()}</td>
                    <td>{item.config_id_code}</td>
                    <td>{item.price_range}</td>
                    <td>{item.last_price}</td>
                    <td>{item.last_price_ID}</td>
                    <td>{item.last_profit}</td>
                    <td>{item.design_id}</td>
                    <td>{item.name}</td> {/* اینجا ممکن است تکراری باشد */}
                    <td>{item.config_sku}</td>
                    <td>{item.collection_name}</td>
                    <td>{item.manufacturer}</td>
                    <td>{item.weave}</td>
                    <td>{item.country_of_manufacture}</td>
                    <td>{item.material}</td> {/* اینجا ممکن است تکراری باشد */}
                    <td>{item.vendor_name}</td>
                    <td>{item.vendor_full_name}</td>
                    <td>{item.shippingh}</td> {/* نام این فیلدها را بررسی کنید */}
                    <td>{item.shippingl}</td>
                    <td>{item.shippingw}</td>
                    <td>{item.shippingmethod}</td>
                    <td>{item.weight}</td>
                </tr>
            ))

        ) : (
            // اگر هیچ داده‌ای وجود نداشته باشد
            <tr>< td colSpan={20}>No sales data available.</ td></tr>
        )}
    </tbody>


</>
  )
}

export default EMReportComponets