import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

function ToperComponents() {
  return (
    
      <Row id='toper-header'className='p-2'>
          <Col sm= {9}>
              <h1>
                  Rugs Luxe
              </h1>
     <p>
     Welcome to RugsLuxe, with nearly two decades of expertise in manufacturing and wholesaling within the rug industry, our journey has been fueled by an unwavering commitment to excellence and customer satisfaction.

     </p>
   <p>
   At RugsLuxe, we envision creating a haven for rug enthusiasts, a place where exquisite designs, premium quality, and affordability converge. Our vision is to be the go-to destination for individuals seeking the perfect rugs that effortlessly elevate their spaces, adding a touch of warmth, style, and personality to every room.
   </p>
          </Col>
       
          <Col sm={3}>
              <Card.Img src={require("./logo.png")} className='card-img-top mx-5' style={{
                  height: '200px',
                  width: '300px',
                  
              }} />

          
          </Col>

      </Row>
      
      
      
      
  )
}

export default ToperComponents