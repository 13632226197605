import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByManufacturerComponets from './EMSalesReportByManufacturerComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByManufacturerScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Manufacturer"} searchTitre ={'Search by Manufacturer'} itemForChart={"sku__config_info__manufacturer"} categoryFilttering={'sku__config_info__manufacturer'}>

<EMSalesReportByManufacturerComponets />

</ReportFunction>

    );
};

export default EMSalesReportByManufacturerScreen;