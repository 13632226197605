import React from 'react'
import { Nav } from 'react-bootstrap'
import {  useNavigate } from 'react-router-dom';

export function LogoutAction() {
  const navigate = useNavigate();
  const logOutHandel = () => {
    localStorage.clear()
    navigate('/')
  }
  return (
    <>

      <Nav.Link onClick={logOutHandel} >Logout</Nav.Link>
    </>
  )
}

export default LogoutAction