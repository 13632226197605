import React from 'react'
import EmContactsBodyComponents from './EmContactsBodyComponents'

function EmContactsHomeScreen() {
  return (
    <EmContactsBodyComponents>
        <h1>
            See ALL contacts in here
        </h1>
    </EmContactsBodyComponents>
  )
}

export default EmContactsHomeScreen