import EmployeeBodyComponents from '../EmployeeBodyComponents'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import CheckoutSteps from '../../mainesrc/CheckoutSteps';
import LoaderComponents from '../../mainesrc/LoaderComponents';
import Message from '../../mainesrc/Message';
import {
  GET_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE, POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP1,

  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP2_DOWNLOADEMPTY,
  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_DOWNLOADEMPTY,
  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_UPLOAD,
  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_RECEIVE_FULL_FILE,


  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP3_DOWNLOADEMPTY,
  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP3_DOWNLOADEMPTY,
  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP3_UPLOAD,
  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP3_RECEIVE_MAPPING_FULL_FILE,
  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP4_RESET_DOCUMENT,

  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP4_DOWNLOAD_FINAL_FILE,
  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP4_RECAVE_FINAL_FILE,

  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_DOWNLOAD_ORGINAL_FILE,

  POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_HANDEL_IMAGE,
} from '../../mainesrc/AllLinkConst';



function EmVendorCleanDataWorkSpaceScreen() {
  const { pk } = useParams();
  const [workSpaceDetaile, setWorkSpaceDetaile] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [started, setStarted] = useState(true)
  const [step1, setStep1] = useState(false)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)
  const [step4, setStep4] = useState(false)
  const [closed, setClosed] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileIncomplitedStep2, setSelectedFileIncomplitedStep2] = useState(null);
  const [selectedFileChangeFullStep2, setSelectedFileChangeFullStep2] = useState(null);
  const [selectedFileChangeFullStep4, setSelectedFileChangeFullStep4] = useState(null);


  const [selectedFileIncomplitedStep3, setSelectedFileIncomplitedStep3] = useState(null);
  const [selectedFileChangeFullStep3, setSelectedFileChangeFullStep3] = useState(null);
  const [documetId, setDocumetId] = useState();
  const [vendorName, setVendorName] = useState();
  const [status, setStatus] = useState();
  const formRef = useRef(null);
  // -----------  Started Page
  useEffect(() => {
    const fetchCompanys = async () => {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = userInfo.token;
        setLoading(true)
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const { data } = await axios.get(`${GET_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE}${pk}`, config);
        setWorkSpaceDetaile(data);
        setStatus(data.status)
        setDocumetId(data.id);
        setVendorName(data.vendor_name)
        console.log(data);
        // console.log(data)
        switch (data.status) {
          case 'Started':
            setStep1(true)
            setStarted(false)
            break
          case 'Step2':
          case 'IncompleteStep2':
            setStep2(true)
            break
          case 'Step3':
          case 'IncompleteStep3':
            setStep3(true)
            break
          case 'Step4':
          case 'IncompleteStep4':
            setStep4(true)
            break
          case 'Closed':
            setClosed(true)
            break
          default:
            setStep1(false)
        }
        setLoading(false)

      } catch (error) {
        const errorMessage = error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message;

        setLoading(false)
        setErrorMessage(errorMessage);
      }
    };

    fetchCompanys();

  }, [pk]);

  //  Handell all work total

  const restDoc = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    const formData = new FormData();
    // formData.append('file', selectedFile);
    // formData.append('selectedRadio', vendorName);
    formData.append('document_id', documetId);



    try {
      const response = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP4_RESET_DOCUMENT, formData, config);
      const jsonData = response.data.data;

      setLoading(false)


      if (jsonData === 'ok') {
        setStatus(response.data.status)

        setLoading(false);
        setStep1(true)
        setStep2(false)
        setStep3(false)
        setStep4(false)
        // navigate('/vendor/step2VendorLink');
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
      setErrorMessage(errorMessage);
      console.error('Error uploading file:', error);
      console.log(errorMessage)
    }

  }
  const handelDownloadOrginalFile = async (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_DOWNLOAD_ORGINAL_FILE, { 'document_id': documetId }, config
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'OrginalFile.xlsx');
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.error('Error downloading file:', error);
    });
  };

  
  // -----------  Handel Step1-----------------------------

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handelStep1 = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('selectedRadio', vendorName);
    formData.append('document_id', documetId);



    try {
      const response = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP1, formData, config);
      const jsonData = response.data.data;
      setLoading(false)
      setStep1(false)
      setStep2(true)
      setStarted(true)

      if (jsonData === 'ok') {
        setStatus(response.data.status)
        formRef.current.reset();
        setLoading(false);
        // navigate('/vendor/step2VendorLink');
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
      setErrorMessage(errorMessage);


    }
  };

  // -----------  Handel Step 2------------------------------
  // ------------Download Empty Cell
  const handleClickDownloadFileStep2 = (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP2_DOWNLOADEMPTY, { 'document_id': documetId }, config
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'empty_cell.xlsx');
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.error('Error downloading file:', error);
    });
  };

  // ---------------DownLoad InComplited File

  const handleClickDownloadIncomplateFileStep2 = (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;
    formRef.current.reset();
    const config = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_DOWNLOADEMPTY, { 'document_id': documetId }, config
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'IncompleteStep2.xlsx');
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.error('Error downloading file:', error);
    });
  };

  // ---------------Upload InComplited File


  const handleFileChangeInComplitedStep1 = (e) => {
    setSelectedFileIncomplitedStep2(e.target.files[0]);
  };

  const handleUploadChangeInComplitedStep2 = async (e) => {
    formRef.current.reset();

    e.preventDefault();
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    const formData = new FormData();
    formData.append('file', selectedFileIncomplitedStep2);
    // formData.append('selectedRadio', vendorName);
    formData.append('document_id', documetId);



    try {
      const response = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_UPLOAD, formData, config);
      const jsonData = response.data.data;
      setLoading(false)
      // setStep1(false)
      // setStep2(true)
      formRef.current.reset();
      if (jsonData === 'ok') {
        setStatus(response.data.status)
        setStatus(response.data.status)
        setLoading(false);
        // navigate('/vendor/step2VendorLink');
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
      setErrorMessage(errorMessage);


    }
  };


  //---------------- Take file that is full now

  const handleFileChangeFullStep2 = (e) => {
    setSelectedFileChangeFullStep2(e.target.files[0]);


  }

  const handleUploadFullStep2 = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    const formData = new FormData();
    formData.append('file', selectedFileChangeFullStep2);
    formData.append('selectedRadio', vendorName);
    formData.append('document_id', documetId);
    try {
      const response = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_RECEIVE_FULL_FILE, formData, config);
      const jsonData = response.data.data;
      
      if (jsonData === 'ok') {
        setStatus(response.data.status)
        handleClickImageFile()
        // setLoading(false)
        // setStep2(false)
        // setStep3(true)
        formRef.current.reset();
        // setLoading(false);

      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
      setErrorMessage(errorMessage);


    }


  }
// 
// ------------------- Handel image file-------------


  const handleClickImageFile = async () => {
    // e.preventDefault();
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    const formData = new FormData();
    // formData.append('file', selectedFileChangeFullStep2);
    formData.append('selectedRadio', vendorName);
    formData.append('document_id', documetId);
    try {
      const response = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_HANDEL_IMAGE, formData, config);
      const jsonData = response.data.data;
      // setLoading(false)
      // setStep2(false)
      // setStep3(true)

      if (jsonData === 'ok') {
        setStatus(response.data.status)
        setLoading(false)
        setStep2(false)
        setStep3(true)
        formRef.current.reset();
        // setLoading(false);

      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
      setErrorMessage(errorMessage);


    }


  }


  //----------- --------  Handel Step 3----------------------------
  // ------------Download Empty Cell
  const handleClickDownloadFileStep3 = (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP3_DOWNLOADEMPTY, { 'document_id': documetId }, config
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'mapping_empty.xlsx');
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.error('Error downloading file:', error);
    });
  };

  // ---------------DownLoad InComplited File

  const handleClickDownloadIncomplateFileStep3 = (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;
    formRef.current.reset();
    const config = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP3_DOWNLOADEMPTY, { 'document_id': documetId }, config
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'IncompleteStep3.xlsx');
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.error('Error downloading file:', error);
    });
  };

  // ---------------Upload InComplited File


  const handleFileChangeInComplitedStep3 = (e) => {
    setSelectedFileIncomplitedStep3(e.target.files[0]);
  };

  const handleUploadChangeInComplitedStep3 = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    const formData = new FormData();
    formData.append('file', selectedFileIncomplitedStep3);
    // formData.append('selectedRadio', vendorName);
    formData.append('document_id', documetId);



    try {
      const response = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP3_UPLOAD, formData, config);
      const jsonData = response.data.data;
      setLoading(false)

      formRef.current.reset();
      if (jsonData === 'ok') {
        setStatus(response.data.status)
        setStatus(response.data.status)
        setLoading(false);

      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
      setErrorMessage(errorMessage);


    }
  };


  //---------------- Take file that Mappnig is full now

  const handleFileChangeFullStep3 = (e) => {
    setSelectedFileChangeFullStep3(e.target.files[0]);


  }

  const handleUploadFullStep3 = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    const formData = new FormData();
    formData.append('file', selectedFileChangeFullStep3);
    formData.append('selectedRadio', vendorName);
    formData.append('document_id', documetId);
    try {
      const response = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP3_RECEIVE_MAPPING_FULL_FILE, formData, config);
      const jsonData = response.data.data;
      setLoading(false)
      // setStep2(false)
      // setStep3(true)

      if (jsonData === 'ok') {
        setStatus(response.data.status)
        setStep3(false)
        setStep4(true)
        formRef.current.reset();
        setLoading(false);

      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
      setErrorMessage(errorMessage);


    }


  }


  //-----------  Handel Step 4

  const handelDownloadFinalFileStep4 = (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;
    formRef.current.reset();
    const config = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
    axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP4_DOWNLOAD_FINAL_FILE, { 'document_id': documetId }, config
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'FinalFile.zip');
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.error('Error downloading file:', error);
    });

  }




  const handleFileChangestep4 = (e) => {
    e.preventDefault();

    setSelectedFileChangeFullStep4(Array.from(e.target.files));
   

  }
  const handelStep4RegisterFinalFile = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }

    const formData = new FormData();
    // formData.append('file', selectedFileChangeFullStep4);
    selectedFileChangeFullStep4.forEach(file => {
      console.log(documetId)
      formData.append('file', file);
    });
    formData.append('selectedRadio', vendorName);
    formData.append('document_id', documetId);
    try {
      const response = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP4_RECAVE_FINAL_FILE, formData, config);
      const jsonData = response.data.data;
      setLoading(false)
      // setStep2(false)
      // setStep3(true)

      if (jsonData === 'ok') {
        setStatus(response.data.status)
        // setStep3(false)
        setStep4(false)
        setClosed(true);
        // formRef.current.reset();
        setLoading(false);

      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
      setErrorMessage(errorMessage);


    }


  }












  return (

    <EmployeeBodyComponents>
<Container fluid>


      <CheckoutSteps step1="Vendor" step1Link='/employee/vendor' step2='Doucuments' step2Link='/employee/vendor/cleandata/documents' step3="Worke Space" />
      {loading && <LoaderComponents />}
      {errorMessage && errorMessage.length > 0 && <Message variant='info' text={errorMessage} />}
      <Row>
        <Col>
          Document id:{' '}
          {workSpaceDetaile.id}
        </Col>
        <Col>
          total rows:{' '}
          {workSpaceDetaile.total_rows}
        </Col>
        <Col>

          Status:{' '}
          <strong>

            {status}
          </strong>

        </Col>
        <Col>
          {/* {workSpaceDetaile && workSpaceDetaile.documents_info && workSpaceDetaile.documents_info.user_created && ( */}
          <p>User Created: <strong>

            {workSpaceDetaile.user_created}
          </strong>
          </p>
          {/* )} */}
        </Col>
        <Col>
          {/* {workSpaceDetaile && workSpaceDetaile.documents_info && workSpaceDetaile.documents_info.user_created && ( */}
          <p>Vendor : <strong>

            {vendorName}
          </strong>
          </p>
          {/* )} */}
        </Col>

      </Row>
      <hr></hr>
      <Row>
        <Col className='p-3'>
          <div hidden={!started}>

          <Button onClick={restDoc} className='m-2' hidden={closed}>
            Reset Document
          </Button>

          <Button variant="primary" type="submit" className='m-2' hidden={!closed}
              onClick={handelDownloadFinalFileStep4}
              >

              Download the final file
            </Button>
    
          <Button
           onClick={handelDownloadOrginalFile}
           >
            Download the vendor file
          </Button>

            </div>

        </Col>
      </Row>
      <Row>
        {/* -----------------Step 1 --------------------- */}
        <Col >
          <h4>
            Step1:{'  '}input File
          </h4>

          <Form hidden={!step1}
            onSubmit={handelStep1}
          >
            <h4>
              Import the vendor file to cleane
            </h4>
            <Form.Group controlId="formFile" >
              <Row className='g-2'>
                <Col xl={3} xxl={2} >
                  <Button variant="primary" type="submit" className='w-100'  > Upload Vendor File</Button>
                </Col>
                <Col xl={9} xxl={10}>
                  <Form.Control type="file"
                    onChange={handleFileChange}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>



        </Col>
        <hr className='m-2'></hr>

        {/* -----------------Step 2 ---------------------- */}
        <Col lg={12} className='p-2' >
          <h4>

            Step2: {'  '} Control Empty Cell

          </h4>

          <div hidden={!step2}>

            <p>
              <Button
                onClick={handleClickDownloadFileStep2}
                className='mx-2' >
                DownLoad File
              </Button>
              Please download the Excel file that contains empty cells.
            </p>




            <Form
              // onSubmit={handleSubmit}
              ref={formRef}
            >
              <Form.Group controlId="formFile">
                <Row className='g-2'>
                  <Col sm={12}>

                    <p >
                      If you have not completed the file completely and you plan to continue in the future, you can save it in this section
                    </p>
                  </Col>
                  <Col md={2}  >

                    <Button className='mx-2'
                      onClick={handleClickDownloadIncomplateFileStep2}

                    >
                      Download unfinished file
                    </Button>
                  </Col>
                  <Col md={2} >

                    <Button className='w-100'
                      onClick={handleUploadChangeInComplitedStep2}
                    >
                      Upload unfinished file
                    </Button>
                  </Col>

                  <Col md={8} >

                    <Form.Control type="file"
                      onChange={handleFileChangeInComplitedStep1}
                    />
                  </Col>

                </Row>
              </Form.Group>
            </Form>

            <Row className='my-2'>
              <Col className='my-2' sm={12}>
                The entry point of the completed file              </Col>

              <Col md={3} lg={2} >
                <Button variant="primary" type="submit" className='w-100' onClick={handleUploadFullStep2}  >Upload </Button>
              </Col>
              <Col md={9} lg={10}>
                <Form>
                  <Form.Group controlId="formFile" >
                    <Form.Control type="file" onChange={handleFileChangeFullStep2} />
                  </Form.Group>
                </Form>
              </Col>
            </Row>

          </div>




        </Col>
        <hr></hr>
        {/* ------------------------  Step 3 -------------------------- */}
        <Col lg={12} className='p-2'>



          <h4>

            Step3: {'  '} Complited  database data
          </h4>

          <div hidden={!step3}>


            {/* <p>
              <Button
                onClick={handleClickImageFile}
                className='mx-2' >
                Handel Image
              </Button>
              Just Push
            </p> */}


            <p>
              <Button
                onClick={handleClickDownloadFileStep3}
                className='mx-2' >
                DownLoad File
              </Button>
              Please download the Excel mapping file that needs to be completed from this section
            </p>




            <Form
              // onSubmit={handleSubmit}
              ref={formRef}
            >
              <Form.Group controlId="formFile">
                <Row className='g-2'>
                  <Col sm={12}>

                    <p >
                      If you have not completed the file completely and you plan to continue in the future, you can save it in this section
                    </p>
                  </Col>
                  <Col md={2}  >

                    <Button className='mx-2'
                      onClick={handleClickDownloadIncomplateFileStep3}

                    >
                      Download unfinished file
                    </Button>
                  </Col>
                  <Col md={2} >

                    <Button className='w-100'
                      onClick={handleUploadChangeInComplitedStep3}
                    >
                      Upload unfinished file
                    </Button>
                  </Col>

                  <Col md={8} >

                    <Form.Control type="file"
                      onChange={handleFileChangeInComplitedStep3}
                    />
                  </Col>

                </Row>
              </Form.Group>
            </Form>

            <Row className='my-2'>
              <Col className='my-2' sm={12}>
                The entry point of the completed file              </Col>

              <Col md={3} lg={2} >
                <Button variant="primary"
                  type="submit"
                  className='w-100'
                  onClick={handleUploadFullStep3}
                >
                  Upload
                </Button>
              </Col>
              <Col md={9} lg={10}>
                <Form>
                  <Form.Group controlId="formFile" >
                    <Form.Control type="file" onChange={handleFileChangeFullStep3} />
                  </Form.Group>
                </Form>
              </Col>
            </Row>














            {/* <p>

              <Button
                //  onClick={handleClickDownloadFile}
                className='mx-2' >
                دانلود فایل
              </Button>
              لطفا فایل اکسل را که شامل مپینگ های خالی می باشد را دانلود کنید
            </p>


            <Form
            // onSubmit={handleSubmit}
            >
              <Form.Group controlId="formFile">
                <Form.Label className='m-3'>
                  درصرتی که فایل را بطور کامل تکمیل نکرده اید و قصد دارید در آینده ادامه دهید می توانید در این قسمت آن را ذخیره کنید
                </Form.Label>
                <Form.Control type="file"
                // onChange={handleFileChange}
                />
                <Button className='m-2'
                // onClick={handleClickDownloadFile}
                >
                  آپلود فایل نیمه کاره
                </Button>
                <Button
                >
                  دانلود فایل نیمه کاره
                </Button>
              </Form.Group>
            </Form>
            <Form>


              <Form.Group controlId="formFile">
                <Form.Label className='m-3'>      محل ورود فایل مرحله سه</Form.Label>
                <Form.Control type="file"
                // onChange={handleFileChange} 
                />
              </Form.Group>
              <Button variant="primary" type="submit" className='m-3'>آپلود فایل</Button>

            </Form> */}









          </div>



        </Col>
        <hr></hr>

        {/*-----------------------  Step 4 ------------------------- */}
        <Col lg={12} className='p-2'>
          <h4>
            Step4: {'  '} Complited  Clean Data
          </h4>
          <div hidden={!step4}>
            <h3>
              Confirming the final file and registering it in the database and closing the document            </h3>
            <Button variant="primary" type="submit" className='m-3'
              onClick={handelDownloadFinalFileStep4}
            >

              Download the final file
            </Button>

            <Form
              onSubmit={handelStep4RegisterFinalFile}
            >
              <h4>
                Import the final file
              </h4>
              <Form.Group controlId="formFile" >
                <Row className='g-2'>
                  <Col xl={3} xxl={2} >
                    <Button variant="primary" type="submit" className='w-100'  > Upload</Button>
                  </Col>
                  <Col xl={9} xxl={10}>
                    <Form.Control type="file" multiple
                      onChange={handleFileChangestep4}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Form>


          </div>

        </Col>
      </Row>




      </Container>



    </EmployeeBodyComponents >


  )
}

export default EmVendorCleanDataWorkSpaceScreen