import React, { useState, useEffect } from 'react';
import GuestBodyComponents from './GuestBodyComponents';
import { Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { GET_GUEST_HOME_PRODUCTLIST_SHOW_ALL } from '../mainesrc/AllLinkConst';
import Product from './product/Product';
import ToperComponents from './ToperComponents';
import Pagination from './Pagination';

function GuestHomeScreen() {
    const [products, setProducts] = useState([]);
    const [serrorMessage, setErrorMessage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 50; // تعداد محصولات در هر صفحه

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                };

                const { data } = await axios.get(`${GET_GUEST_HOME_PRODUCTLIST_SHOW_ALL}?page=${currentPage}&page_size=${itemsPerPage}`, config);
                
                setProducts(data.results); // فرض بر این است که داده‌ها در data.results قرار دارند
                setTotalPages(Math.ceil(data.count / itemsPerPage)); // فرض بر این است که تعداد کل محصولات در data.count قرار دارد
            } catch (error) {
                const errorMessage = error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message;

                setErrorMessage(errorMessage);
            }
        };

        fetchProducts();
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <GuestBodyComponents>
            <div>
                <ToperComponents />
                <h3>See the Products</h3>

                <Row>
                    {products.map(product => (
                        <Col key={product.id} sm={12} md={6} lg={3} xl={3}>
                            <Product product={product} />
                        </Col>
                    ))}
                </Row>

                {/* Pagination */}
                <Pagination 
                currentPage={currentPage} 
                totalPages={totalPages} 
                onPageChange={handlePageChange} 
            />
            </div>
        </GuestBodyComponents>
    );
}

export default GuestHomeScreen;