import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import { CDBSidebar, CDBSidebarContent, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem, CDBSidebarFooter } from 'cdbreact';
// import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { CDBSidebar, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem, CDBSidebarFooter } from 'cdbreact';

const EmployeeSidebar = () => {
    // ایجاد state برای نگهداری وضعیت سایدبار
    const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
        // مقدار اولیه را از localStorage می‌خوانیم
        const storedSidebarState = localStorage.getItem('isSidebarOpen');
        return storedSidebarState === 'true'; // اگر مقدار موجود در localStorage برابر با "true" باشد، سایدبار باز است
    });

    // تابعی برای تغییر وضعیت سایدبار
    const toggleSidebar = () => {
        setIsSidebarOpen(prevState => {
            const newState = !prevState; // تغییر وضعیت
            localStorage.setItem('isSidebarOpen', newState); // ذخیره وضعیت جدید در localStorage
            return newState; // بازگشت به وضعیت جدید
        });
    };

    return (
        <div style={{ display: 'flex', minHeight: '150vh', overflow: 'scroll initial' }}>
            {/* سایدبار با قابلیت جمع شدن */}
            <CDBSidebar textColor="#fff" backgroundColor="#343a40" toggled={!isSidebarOpen}>
                <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large" onClick={toggleSidebar} style={{ cursor: 'pointer' }} />}>
                    Epmloyee Dashboard
                </CDBSidebarHeader>

                <CDBSidebarMenu>
                    {/* هدر برای Dashboard */}


                    <div style={{ padding: '10px', color: '#fff', fontWeight: 'bold', fontSize: isSidebarOpen ? '1rem' : '0.6rem'  }}>
        Dashboard
    </div>
   

                    {/* لینک‌های دیگر */}
                    <NavLink exact to="/employee" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="columns" style={{ fontSize: '0.8em' }} title="Dashboard">Dashboard</CDBSidebarMenuItem>
                    </NavLink>

                    {/* هدر برای Clean Data */}
                    <div style={{ padding: '10px', color: '#fff', fontWeight: 'bold', fontSize: isSidebarOpen ? '1rem' : '0.6rem'  }}>
                        Clean Data
                    </div>

                    {/* زیرمجموعه‌ها */}
                    <NavLink to="/employee/vendor/cleandata" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="file" style={{ fontSize: '0.8em' }} title="Clean Data Workspace">Clean Data Workspace</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/employee/vendor/cleandata/documents" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="file" style={{ fontSize: '0.8em' }} title="See All Documents">See All Documents</CDBSidebarMenuItem>
                    </NavLink>

                    {/* هدر برای Report */}
                    <div style={{ padding: '10px', color: '#fff', fontWeight: 'bold', fontSize: isSidebarOpen ? '1rem' : '0.6rem'  }}>
                        Report
                    </div>

                    {/* زیرمجموعه‌ها */}
                    <NavLink to="/report/sellreport_by_vendor_name" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Vendor Name">Vendor Name</CDBSidebarMenuItem>
                    </NavLink>

                    <NavLink to="/report/sellreport" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="SKU">SKU</CDBSidebarMenuItem>
                    </NavLink>

                    <NavLink to="/report/sellreport_by_color" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Color">Color</CDBSidebarMenuItem>
                    </NavLink>

                    <NavLink to="/report/sellreport_by_category" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Category">Category</CDBSidebarMenuItem>
                    </NavLink>

                    <NavLink to="/report/sellreport_by_collection_name" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Collection Name">Collection Name</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_shape" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Shape">Shape</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_material" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Material">Material</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_country" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Country">Country</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_weave" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Weave">Weave</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_depatment" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Depatment">Depatment</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_design_id" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Design id">Design id</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_designer" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Designer">Designer</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_size" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Size">Size</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_shippingl" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Shipping L">Shipping L</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_shippingh" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Shipping H">Shipping H</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_shippingw" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Shipping W">Shipping W</CDBSidebarMenuItem>
                    </NavLink>
                    {/* <NavLink to="/report/sellreport_by_price" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Price">Price</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="/report/sellreport_by_pricerange" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Price Range">Price Range</CDBSidebarMenuItem>
                    </NavLink> */}
                    <NavLink to="/report/sellreport_by_manufacturer" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="Manufacturer">Manufacturer</CDBSidebarMenuItem>
                    </NavLink>














                    <NavLink to="/report" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="chart-line" style={{ fontSize: '0.8em' }} title="All Record Report">All Record Report</CDBSidebarMenuItem>
                    </NavLink>
                </CDBSidebarMenu>

              

       

                <CDBSidebarFooter>

                    <NavLink to="https://api.rlinternaltoolshub.ir/admin" activeClassName="activeClicked">
                        <CDBSidebarMenuItem icon="cog" style={{ fontSize: '0.8em' }} title="Admin Panel">Admin Panel</CDBSidebarMenuItem>
                    </NavLink>



                </CDBSidebarFooter>
            </CDBSidebar>
        </div>
    );
};

export default EmployeeSidebar;

// آیکون‌های Font Awesome:
// columns: برای داشبورد
// user: برای پروفایل
// cog: برای تنظیمات
// file: برای فایل‌ها یا مستندات
// folder: برای پوشه‌ها
// chart-line: برای نمودارها یا آمار
// bell: برای اعلان‌ها
// sign-out-alt: برای خروج