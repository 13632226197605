import React from 'react'
import { Col, Nav, Navbar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'


function CheckoutSteps({ step1, step1Link, step2, step2Link, step3, step3Link, step4, step4Link }) {
    return (
        <Row>
            <Col >
                <Navbar expand="lg" >
                    <Nav className="me-auto" >


                        <Nav className="me-auto">
                            {step1 && step1Link ? (
                                <Link to={step1Link} style={{
                                    textDecoration:'none',
                                }}>
                                    <Nav.Item className='mx-1'>
                                        {step1} /
                                    </Nav.Item>
                                </Link>
                            ) :
                            step1 && !step1Link ? (
                                <Nav.Item className='mx-1'>
                                    {step1}
                                </Nav.Item>
                            ) : null
                             
                             
                             }

                        </Nav>



                        {step2 && step2Link ? (
                            <Link to={step2Link}  style={{
                                textDecoration:'none',
                            }}>
                                <Nav.Item className='mx-1'>
                                    {step2} /
                                </Nav.Item>
                            </Link>
                        ) : 
                     
                        step2 ? (
                            <Nav.Item className='mx-1'>
                                {step2}
                            </Nav.Item>
                        ) : null
                        
                        }



                        {step3 && step3Link ? (
                            <Link to={step3Link}  style={{
                                textDecoration:'none',
                            }}>
                                <Nav.Item className='mx-1'>
                                    {step3} /
                                </Nav.Item>
                            </Link>
                        ) : step3 ? (
                            <Nav.Item className='mx-1'>
                                {step3}
                            </Nav.Item>
                        ) : null}



                        {step4 && step4Link ? (
                            <Link to={step4Link}  style={{
                                textDecoration:'none',
                            }}>
                                <Nav.Item className='mx-1'>
                                    {step4} /
                                </Nav.Item>
                            </Link>
                        ) :step4 ? (
                            <Nav.Item className='mx-1'>
                                {step4}
                            </Nav.Item>
                        ) : null}





                    </Nav>
                </Navbar>
            </Col>
        </Row>
    )
}

export default CheckoutSteps