import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import GuestHomeScreen from '../guest/GuestHomeScreen';
import EmployeeHomeScreen from '../employee/EmployeeHomeScreen';
import CustomerHomeScreen from '../customer/CustomerHomeScreen';
import VendorHomeScreen from '../vendor/VendorHomeScreen';
import LoginScreen from './LoginScreen';
import LogoutAction from './LogoutAction';
import EmContactsHomeScreen from '../employee/emcontacts/EmContactsHomeScreen';
import EmVendorHomeScreen from '../employee/emvendor/EmVendorHomeScreen';
import EmVendorCleanDataScreen from '../employee/emvendor/EmVendorCleanDataScreen';
import EmVendorCleanDataDetailsScreen from '../employee/emvendor/EmVendorCleanDataDetailsScreen';
import EmVendorCleanDataDocScreen from '../employee/emvendor/EmVendorCleanDataDocScreen';
import EmVendorCleanDataWorkSpaceScreen from '../employee/emvendor/EmVendorCleanDataWorkSpaceScreen';
import AddToDataBaseScreen from './AddToDataBaseScreen';
import ProductDetailsScreen from '../guest/product/ProductDetailsScreen';
import ProductDetailsDetailsScreen from '../guest/product/ProductDetailsDetailsScreen';
// import EMVendorReportScreen from '../employee/emvendor/EMVendorReportScreen';
// import ChangeLogTable from '../employee/emvendor/ChangeLogTable';
import EMSalesReportScreen from '../employee/emreport/EMSalesReportScreen';
import EMSalesReportByColorScreen from '../employee/emreport/EMSalesReportByColorScreen';
// import SalesReportChartScreen from '../employee/emreport/SalesReportChartScreen';
import EMVendorReportScreen from '../employee/emreport/EMVendorReportScreen';
import EMSalesReportByCategoryScreen from '../employee/emreport/EMSalesReportByCategoryScreen';
import EMSalesReportByVendorNameScreen from '../employee/emreport/EMSalesReportByVendorNameScreen';
import EMSalesReportByCollectionNameScreen from '../employee/emreport/EMSalesReportByCollectionNameScreen';
import EMSalesReportByDesignIdScreen from '../employee/emreport/EMSalesReportByDesignIdScreen';





import EMSalesReportByShapeScreen from '../employee/emreport/EMSalesReportByShapeScreen';
import EMSalesReportByMaterialScreen from '../employee/emreport/EMSalesReportByMaterialScreen';
import EMSalesReportByCountryScreen from '../employee/emreport/EMSalesReportByCountryScreen';
import EMSalesReportByWeaveScreen from '../employee/emreport/EMSalesReportByWeaveScreen';
import EMSalesReportByDepartmentScreen from '../employee/emreport/EMSalesReportByDepartmentScreen';
import EMSalesReportByDesignerScreen from '../employee/emreport/EMSalesReportByDesignerScreen';
import EMSalesReportBySizeScreen from '../employee/emreport/EMSalesReportBySizeScreen';
import EMSalesReportByShippinglScreen from '../employee/emreport/EMSalesReportByShippinglScreen';
import EMSalesReportByShippinghScreen from '../employee/emreport/EMSalesReportByShippinghScreen';
import EMSalesReportByShippingwScreen from '../employee/emreport/EMSalesReportByShippingwScreen';
import EMSalesReportByShippingmethodScreen from '../employee/emreport/EMSalesReportByShippingmethodScreen';
import EMSalesReportByPriceScreen from '../employee/emreport/EMSalesReportByPriceScreen';
import EMSalesReportByPriceRangeScreen from '../employee/emreport/EMSalesReportByPriceRangeScreen';
import EMSalesReportByManufacturerScreen from '../employee/emreport/EMSalesReportByManufacturerScreen';
function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<GuestHomeScreen />} />
          <Route path='/product/:pk' element={<ProductDetailsScreen />} />
          <Route path='/product/details/:pk' element={<ProductDetailsDetailsScreen />} />

          <Route path='/login' element={<LoginScreen />} />
          <Route path='/logout' element={<LogoutAction />} />          
          <Route path='/customer' element={<CustomerHomeScreen />} />
          <Route path='/employee' element={<EmployeeHomeScreen />} />
          <Route path='/employee/contacts' element={<EmContactsHomeScreen />} />
          <Route path='/employee/vendor' element={<EmVendorHomeScreen />} />
          <Route path='/employee/vendor/cleandata' element={<EmVendorCleanDataScreen />} />
          <Route path='/employee/vendor/cleandata/documents' element={<EmVendorCleanDataDocScreen />} />
          <Route path='/employee/vendor/cleandata/:pk' element={<EmVendorCleanDataDetailsScreen />} />
          <Route path='/employee/vendor/cleandata/workspace/:pk' element={<EmVendorCleanDataWorkSpaceScreen />} />
          <Route path='/vendor' element={<VendorHomeScreen />} />
          <Route path='/addtodatabase' element={<AddToDataBaseScreen />} />
          
          
          <Route path='/report' element={<EMVendorReportScreen />} />
          {/* <Route path='/report/page2' element={<ChangeLogTable />} /> */}
          <Route path='/report/sellreport' element={<EMSalesReportScreen />} />
          <Route path='/report/sellreport_by_color' element={<EMSalesReportByColorScreen />} />
          <Route path='/report/sellreport_by_category' element={<EMSalesReportByCategoryScreen />} />

          {/* _____________NEW_____________ */}
          <Route path='/report/sellreport_by_shape' element={<EMSalesReportByShapeScreen />} />
          <Route path='/report/sellreport_by_material' element={<EMSalesReportByMaterialScreen />} />
          <Route path='/report/sellreport_by_country' element={<EMSalesReportByCountryScreen />} />
          <Route path='/report/sellreport_by_weave' element={<EMSalesReportByWeaveScreen />} />
          <Route path='/report/sellreport_by_depatment' element={<EMSalesReportByDepartmentScreen />} />
          <Route path='/report/sellreport_by_designer' element={<EMSalesReportByDesignerScreen />} />
         
          
          <Route path='/report/sellreport_by_size' element={<EMSalesReportBySizeScreen />} />
          <Route path='/report/sellreport_by_shippingl' element={<EMSalesReportByShippinglScreen />} />
          <Route path='/report/sellreport_by_shippingh' element={<EMSalesReportByShippinghScreen />} />
          <Route path='/report/sellreport_by_shippingw' element={<EMSalesReportByShippingwScreen />} />
          <Route path='/report/sellreport_by_shippingmethod' element={<EMSalesReportByShippingmethodScreen />} />
          <Route path='/report/sellreport_by_price' element={<EMSalesReportByPriceScreen />} />
          <Route path='/report/sellreport_by_pricerange' element={<EMSalesReportByPriceRangeScreen />} />
          <Route path='/report/sellreport_by_manufacturer' element={<EMSalesReportByManufacturerScreen />} />
          
          
          
          {/* _________ END ___________________ */}
          <Route path='/report/sellreport_by_vendor_name' element={<EMSalesReportByVendorNameScreen />} />
          <Route path='/report/sellreport_by_collection_name' element={<EMSalesReportByCollectionNameScreen />} />
          <Route path='/report/sellreport_by_design_id' element={<EMSalesReportByDesignIdScreen />} />


          
        </Routes>        
      </div>
    </Router>
  );
}

export default App;
