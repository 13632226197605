import React from 'react'

// import { Bar, Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement);




function EMSalesReportByVendorNameComponets({salesData}) {



  return (
    <>
    <thead>
        <tr>
            <th>Vendor Name</th>
            <th>Total Sales</th>
            <th>Total Added Products</th>
            <th>Average Sales</th>
            <th>Variance Sales</th>
            <th>Max Sales</th>
            <th>Min Sales</th>
            <th>Count Products</th>
        </tr>
    </thead>
    <tbody>
    {Array.isArray(salesData) && salesData.length > 0 ? (

        salesData.map((item) => (
            <tr key={item.sku__config_info__vendor_name__name}>
                <td>{item.sku__config_info__vendor_name__name}</td>
                <td>{item.total_sales}</td>
                <td>{item.total_added_products}</td>
                <td>{item.average_sales}</td>
                <td>{item.variance_sales}</td>
                <td>{item.max_sales}</td>
                <td>{item.min_sales}</td>
                <td>{item.count_products}</td>
            </tr>
        ))

) : (
           
            <tr>< td colSpan={20}>No sales data available.</ td></tr>
        )}
    </tbody>




{/* 
        <div>
    
            <Bar data={data} options={{
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Sales Data by Color',
                    },
                },
            }} />
        </div>

 */}


























    
</>
  )






}

export default EMSalesReportByVendorNameComponets