
import EmployeeBodyComponents from '../EmployeeBodyComponents'
import React, { useState, useEffect } from 'react';
import CheckoutSteps from '../../mainesrc/CheckoutSteps';
import axios from 'axios';
import { Button, Col, InputGroup, Row, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Pagination from './Pagination'; // مسیر صحیح فایل Pagination را وارد کنید
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LoaderComponents from '../../mainesrc/LoaderComponents';
// import { useNavigate } from 'react-router-dom'; // استفاده از useNavigate برای هدایت به صفحه دیگر
// import { GET_REPORT_SELLREPORT } from '../../mainesrc/AllLinkConst';
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement);
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);



function ReportFunction({ children, link, titre, searchTitre, itemForChart, categoryFilttering }) {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(2);
    const pageSize = 50
    const queryNeed1 = localStorage.getItem('query_need1') || null; // مقدار پیش‌فرض در صورت نبودن داده
    const queryNeedFood1 = localStorage.getItem('query_need_food1') || null; // مقدار پیش‌فرض در صورت نبودن داده
  const [loading, setLoading] = useState(false);

    // const totalPages = Math.ceil(totalCount / pageSize);
    const [salesData, setSalesData] = useState([]);
    // const [titre, setTitre] = useState();
    // const [startDate, setStartDate] = useState('');
    // const [endDate, setEndDate] = useState('');
    // const [errorMessage, setErrorMessage] = useState(null);
    const [searchInput, setSearchInput] = useState(''); // State برای ورودی جستجو
    // const navigate = useNavigate();

    const [selectedOptions, setSelectedOptions] = useState(() => {
        // بررسی میکنیم که آیا داده‌ای در localStorage وجود دارد یا نه
        const savedSelectedOptions = localStorage.getItem('selectedOptions');
        return savedSelectedOptions ? JSON.parse(savedSelectedOptions) : []; // اگر داده وجود داشته باشه از اون استفاده میکنیم، در غیر این صورت مقدار خالی میزاریم
    });

    const fetchSalesData = async (page = 1) => {
        try {
            setCurrentPage(page);
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            const token = userInfo?.token;
            setLoading(true)

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    start_date: startDate,
                    end_date: endDate,
                    selectedOptions_data: selectedOptions.join(','),
                    searchInput_data: searchInput,
                    page: page,  // اضافه کردن شماره صفحه
                    category_for_filtter: categoryFilttering,
                    query_need: queryNeed1,
                    query_need_food: queryNeedFood1
                },
            };

            const { data } = await axios.get(link, config);
            // console.log(data)
            setSalesData(data.results);  // داده‌های paginated
            setTotalPages(Math.ceil(data.count / pageSize))
            setLoading(false)
            
        } catch (error) {
            console.error('Error fetching sales data:', error);
            setLoading(false)
        }
    };

    
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage(""); // Reset error message
        fetchSalesData(); // Fetch sales data based on the selected dates
    };

    const setDefaultDates = (days) => {
        const today = new Date();
        const start = new Date(today);
        start.setDate(today.getDate() - days);

        // Set the default dates
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);

        // Optionally reset the error message here if needed
        setErrorMessage("");
        // fetchSalesData(); // Fetch sales data after setting default dates
    };

    const handelSearch = (e) => {
        e.preventDefault();

        // console.log(selectedOptions)
        // console.log(searchInput)
        fetchSalesData()

    };



    // ---------------------- Chart Data---------------------


    const labels = salesData.map(item => item[itemForChart]);
    const totalSales = salesData.map(item => item.total_sales);
    const totalAddedProducts = salesData.map(item => item.total_added_products);
    const averageSales = salesData.map(item => item.average_sales);
    const countProducts = salesData.map(item => item.count_products);

    // محاسبه مجموع فروش‌ها
    // const totalSalesSum = totalSales.reduce((acc, val) => acc + val, 0);

    // // محاسبه درصد هر فروش
    // const salesPercentages = totalSales.map(sale => (sale / totalSalesSum) * 100);




    const verticalBarData = {
        labels,
        datasets: [
            {
                label: 'Total Sales',
                data: totalSales,
                backgroundColor: 'rgba(127, 255, 212, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: 'Average Sales',
                data: averageSales,
                type: 'line',
                fill: false,
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 2,
            },
            // {
            //     label: 'Total Added Products',
            //     data: totalAddedProducts,
            //     type:"line",
            //     backgroundColor: 'rgba(153, 102, 255, 0.6)',
            //     borderColor: 'rgba(153, 102, 255, 1)',
            //     borderWidth: 1,
            // },
            {
                label: 'Total Count Products',
                data: countProducts,
                type: "line",
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
        ],
    };




    // const horizontalBarData = {
    //     labels: labels,
    //     datasets: [
    //         {
    //             label: 'Total Sells Products',
    //             // data: totalAddedProducts,
    //             data: totalSales,
    //             backgroundColor: 'rgba(153, 102, 255, 0.6)',
    //         },
    //         {
    //             label: 'Average Sales',
    //             data: averageSales, // Assuming averageSales is defined
    //             type: 'line', // Specify that this dataset is a line chart
    //             fill: false,
    //             borderColor: 'rgba(255, 159, 64, 1)',
    //             borderWidth: 2,
    //         },
    //     ],
    // };




    const options_verticalBarData = {
        indexAxis: 'x',
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 14, // اندازه فونت لژند
                        family: 'Arial', // نوع فونت لژند
                        color: '#333', // رنگ فونت لژند
                    },
                },
            },
            title: {
                display: true,
                text: `Total ${titre}`,
                font: {
                    size: 18, // اندازه فونت عنوان
                    family: 'Arial', // نوع فونت عنوان
                    weight: 'bold', // وزن فونت عنوان
                },
                color: '#333', // رنگ عنوان
            },
        },
        elements: {
            bar: {
                borderRadius: 10, // شعاع گوشه‌ها
                borderWidth: 2, // عرض حاشیه
                borderColor: 'rgba(0, 0, 0, 0.1)', // رنگ حاشیه
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(200, 200, 200, 0.5)', // رنگ خطوط شبکه
                },
            },
            y: {
                grid: {
                    color: 'rgba(200, 200, 200, 0.5)', // رنگ خطوط شبکه
                },
            },
        },
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
            },
        },
    };

    const verticalBarData2 = {
        labels: labels,
        datasets: [
            {
                label: 'Total Added Products',
                data: totalAddedProducts,
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
            {
                label: 'Average Sales',
                data: averageSales,
                type: "line",
                fill: false,
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 2,
            },
            {
                label: 'Total Sales',
                data: totalSales,
                type: 'line',
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: 'Total Count Products',
                data: countProducts,
                type: "line",
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },

        ],
    };


    const options_verticalBarData2 = {
        indexAxis: 'x',
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 14, // اندازه فونت لژند
                        family: 'Arial', // نوع فونت لژند
                        color: '#333', // رنگ فونت لژند
                    },
                },
            },
            title: {
                display: true,
                text: `Total ${titre}`,
                font: {
                    size: 18, // اندازه فونت عنوان
                    family: 'Arial', // نوع فونت عنوان
                    weight: 'bold', // وزن فونت عنوان
                },
                color: '#333', // رنگ عنوان
            },
        },
        elements: {
            bar: {
                borderRadius: 10, // شعاع گوشه‌ها
                borderWidth: 2, // عرض حاشیه
                borderColor: 'rgba(0, 0, 0, 0.1)', // رنگ حاشیه
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(200, 200, 200, 0.5)', // رنگ خطوط شبکه
                },
            },
            y: {
                grid: {
                    color: 'rgba(200, 200, 200, 0.5)', // رنگ خطوط شبکه
                },
            },
        },
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
            },
        },
    };
    // const options_horizontalBarData = {
    //     indexAxis: 'y',
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             position: 'top',
    //             labels: {
    //                 font: {
    //                     size: 14, // اندازه فونت لژند
    //                     family: 'Arial', // نوع فونت لژند
    //                     color: '#333', // رنگ فونت لژند
    //                 },
    //             },
    //         },
    //         title: {
    //             display: true,
    //             text: 'Total Added Products by Color (Horizontal Bar Chart)',
    //             font: {
    //                 size: 18, // اندازه فونت عنوان
    //                 family: 'Arial', // نوع فونت عنوان
    //                 weight: 'bold', // وزن فونت عنوان
    //             },
    //             color: '#333', // رنگ عنوان
    //         },
    //     },
    //     elements: {
    //         bar: {
    //             borderRadius: 10, // شعاع گوشه‌ها
    //             borderWidth: 2, // عرض حاشیه
    //             borderColor: 'rgba(0, 0, 0, 0.1)', // رنگ حاشیه
    //         },
    //     },
    //     scales: {
    //         x: {
    //             beginAtZero: true,
    //             grid: {
    //                 color: 'rgba(200, 200, 200, 0.5)', // رنگ خطوط شبکه
    //             },
    //         },
    //         y: {
    //             grid: {
    //                 color: 'rgba(200, 200, 200, 0.5)', // رنگ خطوط شبکه
    //             },
    //         },
    //     },
    //     layout: {
    //         padding: {
    //             left: 20,
    //             right: 20,
    //             top: 20,
    //             bottom: 20,
    //         },
    //     },
    // };








    // const horizontalBarData2 = {
    //     labels: labels,
    //     datasets: [
    //         {
    //             label: 'Total Added Products',
    //             // data: totalAddedProducts,
    //             data: totalAddedProducts,
    //             backgroundColor: 'rgba(153, 102, 255, 0.6)',
    //         },
    //         {
    //             label: 'Total Sales',
    //             data: totalSales, // Assuming averageSales is defined
    //             type: 'line', // Specify that this dataset is a line chart
    //             fill: false,
    //             borderColor: 'rgba(255, 159, 64, 1)',
    //             borderWidth: 2,
    //         },

    //     ],
    // };
    // {
    //     label: 'Total Added Products',
    //     data: totalAddedProducts,
    //     backgroundColor: 'rgba(153, 102, 255, 0.6)',
    //     borderColor: 'rgba(153, 102, 255, 1)',
    //     borderWidth: 1,
    // },
    // const lineData = {
    //     labels: labels,
    //     datasets: [
    //         {
    //             label: 'Total Sales',
    //             // data: averageSales,
    //             data: totalSales,
    //             fill: false,
    //             borderColor: 'rgba(255, 159, 64, 1)',
    //         },
    //         {
    //             label: 'Total  Add Product',
    //             // data: averageSales,
    //             data: totalAddedProducts,
    //             fill: false,
    //             borderColor: 'rgba(153, 102, 255, 0.6)',
    //         },
    //         {
    //             label: 'Average Sales',
    //             data: averageSales, // Assuming averageSales is defined
    //             type: 'line', // Specify that this dataset is a line chart
    //             fill: false,
    //             borderColor: 'rgba(200, 200, 50, 1)',
    //             borderWidth: 2,
    //         },

    //     ],
    // };



    // useEffect(() => {
    //     // تنظیم تاریخ شروع (دیروز)
    //     const yesterday = new Date();
    //     yesterday.setDate(yesterday.getDate() - 1);
    //     setStartDate(yesterday.toISOString().split('T')[0]); // فرمت YYYY-MM-DD

    //     // تنظیم تاریخ پایان (امروز)
    //     const today = new Date();
    //     setEndDate(today.toISOString().split('T')[0]); // فرمت YYYY-MM-DD
    // }, []); // این آرایه خالی به این معنی است که این effect فقط یک بار در بارگذاری کامپوننت اجرا
    const [startDate, setStartDate] = useState(() => localStorage.getItem('startDate') || ''); // مقدار پیش‌فرض از localStorage
    const [endDate, setEndDate] = useState(() => localStorage.getItem('endDate') || ''); // مقدار پیش‌فرض از localStorage
    const [errorMessage, setErrorMessage] = useState(null);
    // const [searchInput, setSearchInput] = useState(() => localStorage.getItem('searchInput') || ''); // مقدار پیش‌فرض از localStorage


    useEffect(() => {
        const savedStartDate = localStorage.getItem('startDate');
        const savedEndDate = localStorage.getItem('endDate');
        // const savedSearchInput = localStorage.getItem('searchInput');
        // const savedSelectedOptions = localStorage.getItem('selectedOptions');

        if (savedStartDate) {
            setStartDate(savedStartDate);
        }

        if (savedEndDate) {
            setEndDate(savedEndDate);
        }

        // if (savedSearchInput) {
        //   setSearchInput(savedSearchInput);
        // }


    }, []); // این effect فقط یک بار اجرا می‌شود هنگام بارگذاری کامپوننت

    // ذخیره داده‌ها در localStorage هنگام تغییر
    useEffect(() => {
        localStorage.setItem('startDate', startDate);
        localStorage.setItem('endDate', endDate);
        localStorage.setItem('searchInput', searchInput);
        // localStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
    }, [startDate, endDate, searchInput]); // زمانی که این مقادیر تغییر کنند، در localStorage ذخیره می‌شوند


    useEffect(() => {
        // صدا زدن تابع handleSearch بعد از بارگذاری صفحه
        fetchSalesData()

    }, []); // این effect فقط یک بار هنگام بارگذاری صفحه اجرا می‌شود







    //   const [selectedOptions, setSelectedOptions] = useState([
    //     'loloi', 'momeni', 'nourison',
    //     'alora_decor', 'amer', 'karastan',
    //     'couristan', 'dynamic', 'oreintal_weavers',
    //     'radici', 'united_weavers', 'tranc_ocean',
    //     'livabliss'
    // ]);

    // const handleCheckboxChange = (event) => {
    //     const { id, checked } = event.target;

    //     // اگر چک‌باکس انتخاب شده باشد، آن را به لیست اضافه کن
    //     if (checked) {
    //         setSelectedOptions((prev) => [...prev, id]);
    //     } else {
    //         // اگر چک‌باکس انتخاب نشده باشد، آن را از لیست حذف کن
    //         setSelectedOptions((prev) => prev.filter(option => option !== id));
    //     }

    // };

    // بارگذاری وضعیت چک‌باکس‌ها از localStorage هنگام بارگذاری کامپوننت
    useEffect(() => {
        const savedSelectedOptions = localStorage.getItem('selectedOptions');

        if (savedSelectedOptions) {
            setSelectedOptions(JSON.parse(savedSelectedOptions));
        }
    }, []);

    // ذخیره وضعیت چک‌باکس‌ها در localStorage هنگام تغییر

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;

        if (checked) {
            setSelectedOptions((prev) => [...prev, id]);
        } else {
            setSelectedOptions((prev) => prev.filter(option => option !== id));
        }
    };

    useEffect(() => {
        localStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
    }, [selectedOptions]);



    const handelRemveQueryNeed = (e) => {
        // حذف از localStorage
        localStorage.removeItem('query_need1');
        localStorage.removeItem('query_need_food1');
        window.location.reload();
    };


    return (

        <EmployeeBodyComponents>


            <CheckoutSteps step1="Home" step1Link='/employee' />
            {loading && <LoaderComponents />}

            <h4>
                {titre}

            </h4>

            {titre === 'Sells By SKU' &&
                queryNeedFood1 &&
                <div style={{
                    backgroundColor: 'red',
                    // height: '2rem',
                    textAlign: 'center'
                }}>

                    <h5 >
                        Selected By {queryNeedFood1}
                    </h5>
                    <Button onClick={handelRemveQueryNeed}>
                        Remove Selected
                    </Button>
                </div>

            }


            <Form >
                {['checkbox'].map((type) => (
                    <div key={`inline-${type}`} className="m-1">
                        <Form.Check
                            inline

                            label="Loloi"
                            checked={selectedOptions.includes('loloi')}
                            name="group1"
                            type={type}
                            id="loloi"
                            onChange={handleCheckboxChange}


                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('momeni')}

                            label="Momeni"
                            name="group2"
                            type={type}
                            id="momeni"
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('nourison')}

                            label="Nourison"
                            name="group3"
                            type={type}
                            id="nourison"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('alora_decor')}

                            label="Alora Decor"
                            name="group4"
                            type={type}
                            id="alora_decor"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('amer')}

                            label="Amer"
                            name="group5"
                            type={type}
                            id="amer"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('karastan')}

                            label="Karastan"
                            name="group6"
                            type={type}
                            id="karastan"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('couristan')}

                            label="Couristan"
                            name="group7"
                            type={type}
                            id="couristan"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('dynamic')}

                            label="Dynamic"
                            name="group8"
                            type={type}
                            id="dynamic"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('oreintal_weavers')}

                            label="Oreintal Weavers"
                            name="group9"
                            type={type}
                            id="oreintal_weavers"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('radici')}

                            label="Radici"
                            name="group10"
                            type={type}
                            id="radici"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('united_weavers')}

                            label="United Weavers"
                            name="group11"
                            type={type}
                            id="united_weavers"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('tranc_ocean')}

                            label="Tranc Ocean"
                            name="group12"
                            type={type}
                            id="tranc_ocean"
                            onChange={handleCheckboxChange}

                        />
                        <Form.Check
                            inline
                            // defaultChecked
                            checked={selectedOptions.includes('livabliss')}

                            label="Livabliss"
                            name="group13"
                            type={type}
                            id="livabliss"
                            onChange={handleCheckboxChange}

                        />


                    </div>
                ))}
            </Form>


            <div className="d-flex flex-wrap ">

                <Row>
                    <Col sm={4}>
                        <Form onSubmit={handelSearch}>
                            <InputGroup className="m-1"

                            // onKeyDown={handleKeyDown} // اضافه کردن رویداد onKeyDown

                            >
                                <InputGroup.Text as={Button} id="basic-addon1" onClick={(e) => {
                                    // fetchSalesData();
                                    handelSearch(e);
                                }}
                                >Search</InputGroup.Text>
                                <Form.Control
                                    placeholder={searchTitre}

                                    aria-label="Sku search"
                                    aria-describedby="basic-addon1"
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)} // بروزرسانی state ورودی

                                />

                            </InputGroup>
                        </Form>
                    </Col>
                    <Col sm={4}>
                        <InputGroup className="m-1">
                            <InputGroup.Text>Start Date</InputGroup.Text>
                            <Form.Control
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                required
                            />
                        </InputGroup>
                    </Col>
                    <Col sm={4}>
                        <InputGroup className="m-1">
                            <InputGroup.Text>End Date</InputGroup.Text>
                            <Form.Control
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                required
                            />
                        </InputGroup>

                    </Col>



                    <Col sm={12}>
                        {/* دکمه‌های تنظیم تاریخ پیش‌فرض */}
                        <form onSubmit={handleSubmit} className="m-1">
                            <Row>
                                <Col>
                                    <button onClick={() => setDefaultDates(1)} className="btn btn-secondary m-1 w-100">Last Days</button>
                                </Col>
                                <Col>
                                    <button onClick={() => setDefaultDates(3)} className="btn btn-secondary m-1 w-100">Last 3 Days</button>

                                </Col>
                                <Col>
                                    <button onClick={() => setDefaultDates(7)} className="btn btn-secondary m-1 w-100">Last 7 Days</button>
                                </Col>
                                <Col>
                                    <button onClick={() => setDefaultDates(30)} className="btn btn-secondary m-1 w-100">Last Month</button>
                                </Col>
                                <Col>
                                    <button onClick={() => setDefaultDates(90)} className="btn btn-secondary m-1 w-100">Last 3 Months</button>
                                </Col>
                                <Col>
                                    <button onClick={() => setDefaultDates(180)} className="btn btn-secondary m-1 w-100">Last 6 Months</button>
                                </Col>
                                <Col>
                                    <button onClick={() => setDefaultDates(365)} className="btn btn-secondary m-1 w-100">Last Year</button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </div>



            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

            <div className="col-start-2 col-end-3 row-start-3 row-end-4 text-white m-6">
                <div className="table-container"> {/* Added container */}
                    <Table striped bordered hover responsive>
                        {React.Children.map(children, child => {
                            return React.cloneElement(child, {
                                salesData,


                            });
                        })}
                        {errorMessage && <p>{errorMessage}</p>} {/* نمایش پیام خطا */}
                    </Table>
                    {/* <Table columns={columns} data={queryData} /> */}
                </div>
                <div className='p-2'>

                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={fetchSalesData}
                    />
                </div>
                {/* {error && <p>{error}</p>} Display error message */}
            </div>




            {/* <div>
            </div> */}



            <div >

                <div style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px' }}>
                    {/* <div style={{ 
    background: 'linear-gradient(135deg, #333, #555)', // پس‌زمینه تیره با رنگ لایت
    padding: '20px', 
    borderRadius: '10px' 
}}> */}
                    <Bar key="vertical-bar" data={verticalBarData} options={
                        //     {
                        //     responsive: true,

                        //     plugins: {
                        //         legend: { position: 'top' },
                        //         title: { display: true, text: 'Total Sales by Color (Vertical Bar Chart)' },
                        //     },
                        // }
                        options_verticalBarData
                    } />
                </div>
                {/* <div style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px' }}> {/* پس‌زمینه و حاشیه */}
                {/* <Bar key="horizontal-bar" data={horizontalBarData} options={options_horizontalBarData} /> */}
                {/* </div> */}


                <div style={{
                    padding: "5px"
                }}></div>

                <div style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px' }}>
                    {/* <div style={{ 
    background: 'linear-gradient(135deg, #333, #555)', // پس‌زمینه تیره با رنگ لایت
    padding: '20px', 
    borderRadius: '10px' 
}}> */}
                    {/* محتویات شما */}
                    {/* </div> */}
                    <Bar key="vertical-bar" data={verticalBarData2} options={
                        //     {
                        //     responsive: true,

                        //     plugins: {
                        //         legend: { position: 'top' },
                        //         title: { display: true, text: 'Total Sales by Color (Vertical Bar Chart)' },
                        //     },
                        // }
                        options_verticalBarData2
                    } />
                </div>

                {/* <Bar key="horizontal-bar" data={horizontalBarData2} options={
                //     {
                //     indexAxis: 'y',
                //     responsive: true,
                //     plugins: {
                //         legend: { position: 'top' },
                //         title: { display: true, text: 'Total Added Products by Color (Horizontal Bar Chart)' },
                //     },


                // }
                options_horizontalBarData
                } /> */}


                {/* <Line key="line-chart" data={lineData} options={{
                    responsive: true,
                    plugins: {
                        legend: { position: 'top' },
                        title: { display: true, text: 'Average Sales by Color (Line Chart)' },
                    },
                }} /> */}
                {/* 
            <h3>Pie Chart</h3>
            <Pie key="pie-chart" data={pieData} options={{
                responsive: true,
                plugins: {
                    legend: { position: 'top' },
                    title: { display: true, text: 'Total Sales by Color (Pie Chart)' },
                },
            }} /> */}
            </div>







        </EmployeeBodyComponents>

    )
}

export default ReportFunction





