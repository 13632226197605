import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LogoutAction from '../mainesrc/LogoutAction'
import EmployeeSidbar from './EmployeeSidbar'

function EmployeeBodyComponents({ children }) {
    return (
        <>
            <div style={{ display: 'flex' }}>
            <EmployeeSidbar/>

                {/* سایر محتوای صفحه */}
                <div style={{ flex: 1 , overflowX: 'auto', maxWidth: '100vw'}}>
            <header>

            <Navbar expand="lg" bg="dark" variant="dark" style={{
                textColor:"#fff",
                 backgroundColor:"#343a40",
            }}>
                    <Container fluid>
                        <Navbar.Brand>
                            <Nav.Link as={Link} to="/employee" >
                                Home
                            </Nav.Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">

                                <NavDropdown title="Clean Data" id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/employee/vendor/cleandata">
                                        Vendor Info
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/employee/vendor/cleandata/documents">Documents</NavDropdown.Item>
                                    <NavDropdown.Item href="#">Mapping</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Report" id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/report">
                                        All Report
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/report/sellreport_by_vendor_name">Sell Report By Vendor Name</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/report/sellreport">Sell Report By SKU</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/report/sellreport_by_color">Sell Report By Color</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/report/sellreport_by_category">Sell Report By Category</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/report/sellreport_by_collection_name">Sell Report By Collection Name</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/report/sellreport_by_design_id">Sell Report By Design Id</NavDropdown.Item>
                                    {/* <NavDropdown.Item href="#">Mapping</NavDropdown.Item> */}
                                </NavDropdown>
                                <NavDropdown title="Product" id="basic-nav-dropdown" hidden>
                                    <NavDropdown.Item as={Link} to="/employee/vendor/cleandata">
                                      All Product
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/employee/vendor/cleandata/documents">Active Product</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/employee/vendor/cleandata/documents">محصولات در حال حراج</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/employee/vendor/cleandata/documents">Deactive Product</NavDropdown.Item>
                                    {/* <NavDropdown.Item href="#">Mapping</NavDropdown.Item> */}
                                </NavDropdown>
                                {/* <Nav.Link as={Link} to="#" >Report</Nav.Link> */}

                            </Nav>
                                    {/* <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item> */}
                            <Nav className="ms-auto">
                                <LogoutAction />
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header >





            <main className='p-3'>
         {children}


            </main>
                </div>
            </div>

        </>
    )
}

export default EmployeeBodyComponents




       
 