import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByShapeComponets from './EMSalesReportByShapeComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByShapeScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Shape"} searchTitre ={'Search by Shape'} itemForChart={"sku__shape"} categoryFilttering={'sku__shape'}>

<EMSalesReportByShapeComponets />

</ReportFunction>

    );
};

export default EMSalesReportByShapeScreen;