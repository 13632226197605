import React from 'react';
import PropTypes from 'prop-types';
// import './all.main.css';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePageChange = (page) => {
        if (page !== currentPage) {
            onPageChange(page);
        }
    };

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                        Previous
                    </button>
                </li>
                {currentPage > 2 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                1
                            </button>
                        </li>
                        <li className="page-item">
                            <span className="page-link">...</span>
                        </li>
                    </>
                )}
                {currentPage > 1 && (
                    <li className="page-item">
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                            {currentPage - 1}
                        </button>
                    </li>
                )}
                <li className="page-item active">
                    <button className="page-link" onClick={() => handlePageChange(currentPage)}>
                        {currentPage}
                    </button>
                </li>
                {currentPage < totalPages && (
                    <li className="page-item">
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                            {currentPage + 1}
                        </button>
                    </li>
                )}
                {currentPage < totalPages - 1 && (
                    <>
                        <li className="page-item">
                            <span className="page-link">...</span>
                        </li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
