
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Row, Col, Card } from 'react-bootstrap';
import Pagination from './Pagination'; // فرض بر این است که کامپوننت Pagination در همین دایرکتوری است
import { GET_REPORT_PAGE1 } from '../../mainesrc/AllLinkConst';
import EmployeeBodyComponents from '../EmployeeBodyComponents';
import CheckoutSteps from '../../mainesrc/CheckoutSteps';

import { useLocation } from 'react-router-dom';
import LoaderComponents from '../../mainesrc/LoaderComponents';


function EMVendorReportScreen() {
    const location = useLocation();
    const queryNeedFood1 = location.state?.query_need_food1 || ''; // مقدار query_need_food1 از location

    const [changeLogs, setChangeLogs] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
      const [loading, setLoading] = useState(false);
    
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 100;
 const [startDate, setStartDate] = useState(() => localStorage.getItem('startDate') || ''); // مقدار پیش‌فرض از localStorage
    const [endDate, setEndDate] = useState(() => localStorage.getItem('endDate') || ''); // مقدار پیش‌فرض از localStorage
    // تابع برای بارگذاری داده‌ها
    const fetchChangeLogs = async (page) => {
        try {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            const token = userInfo?.token;
            setLoading(true);

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    page,
                    page_size: pageSize,
                    query_need_food: queryNeedFood1, // استفاده از مقدار دریافتی
                    start_date: startDate,
                    end_date: endDate,
             
                },
            };

            const { data } = await axios.get(GET_REPORT_PAGE1, config);
            // console.log(data);
            setLoading(false);

            setChangeLogs(data.results); // داده‌های paginated
            setTotalPages(Math.ceil(data.count / pageSize)); // محاسبه تعداد کل صفحات
        } catch (error) {
            const errorMessage = error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message;
            setLoading(false);

            setErrorMessage(errorMessage);
        }
    };

    // بارگذاری داده‌ها در mount
    useEffect(() => {
        fetchChangeLogs(currentPage);
    }, [currentPage, queryNeedFood1]);


    useEffect(() => {
        const savedStartDate = localStorage.getItem('startDate');
        const savedEndDate = localStorage.getItem('endDate');
        // const savedSearchInput = localStorage.getItem('searchInput');
        // const savedSelectedOptions = localStorage.getItem('selectedOptions');

        if (savedStartDate) {
            setStartDate(savedStartDate);
        }

        if (savedEndDate) {
            setEndDate(savedEndDate);
        }

        // if (savedSearchInput) {
        //   setSearchInput(savedSearchInput);
        // }


    }, []); // این effect فقط یک بار اجرا می‌شود هنگام بارگذاری کامپوننت











    // const [changeLogs, setChangeLogs] = useState([]);
    // const [errorMessage, setErrorMessage] = useState(null);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [totalPages, setTotalPages] = useState(0);
    // const pageSize = 100; // تعداد رکوردها در هر صفحه

    
    // const  queryNeedFood1='VIT12 8 x 10 Moss'
    // تابع برای بارگذاری اده‌ها
    // const fetchChangeLogs = async (page) => {
    //     try {
    //         const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //         const token = userInfo?.token;

    //         const config = {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             params: {
    //                 page,
    //                 page_size: pageSize, // اضافه کردن سایز صفحه به پارامترها
             
    //                 query_need_food: queryNeedFood1
    //             },
    //         };

    //         const { data } = await axios.get(GET_REPORT_PAGE1, config);
    //         console.log(data);
    //         setChangeLogs(data.results); // داده‌های paginated
    //         setTotalPages(Math.ceil(data.count / pageSize)); // محاسبه تعداد کل صفحات
    //     } catch (error) {
    //         const errorMessage = error.response && error.response.data.detail
    //             ? error.response.data.detail
    //             : error.message;
    //         setErrorMessage(errorMessage);
    //     }
    // };

    // بارگذاری داده‌ها هنگام تغییر صفحه
    // useEffect(() => {
    //     fetchChangeLogs(currentPage);
    // }, [currentPage]); // فقط زمانی که currentPage تغییر کند، داده‌ها بارگذاری می‌شوند

    // محاسبه داده‌های مربوط به صفحه فعلی
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

  

    return (
        <EmployeeBodyComponents>
      {loading && <LoaderComponents />}

            <CheckoutSteps step1="Home" step1Link='/employee' />
            <h4 >
                Report
            </h4>
            <div className="table-container" >
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>SKU</th>
                            <th>Create Date</th>
                            <th>UPC</th>
                            <th>category</th>
                            <th>material</th>
                            <th>size</th>
                            <th>free_rugpad</th>
                            <th>shape</th>
                            <th>color</th>
                            <th>color_filter</th>
                            <th>active</th>
                            <th>Real Time Price ID</th>
                            <th>Real Time Price </th>
                            <th>Real Time Profit</th>
                            <th>Vendor Inventory</th>
                            <th>Vendor Sales</th>
                            <th>Add Product</th>
                            <th>Config ID Code</th>
                            <th>Price Range</th>
                            <th>Last Price</th>
                            <th>Last Price ID</th>
                            <th>Last Profit</th>
                            <th>design id</th>
                            <th>Config name</th>
                            <th>Config Sku</th>
                            <th>collection name</th>
                            <th>manufacturer</th>
                            <th>weave</th>
                            <th>country_of_manufacture</th>
                            <th>material</th>
                            <th>vendor_name name</th>
                            <th>fullName</th>
                            <th>shippingh</th>
                            <th>shippingl</th>
                            <th>shippingw</th>
                            <th>shippingmethod</th>
                            <th>weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        {changeLogs.map((log) => (
                            <tr key={log.id}>
                                <td>{log.name}</td>
                                <td>{log.sku}</td>
                                <td>{new Date(log.crearte_in_db).toLocaleString()}</td>
                                <td>{log.upc}</td>
                                <td>{log.category}</td>
                                <td>{log.material}</td>
                                <td>{log.size}</td>
                                <td>{log.free_rugpad}</td>
                                <td>{log.shape}</td>
                                <td>{log.color}</td>
                                <td>{log.color_filter}</td>
                                <td>{log.active ? 'Yes' : 'No'}</td>
                                <td>{log.real_time_price_id}</td>
                                <td>{log.real_time_price}</td>
                                <td>{log.real_time_profit}</td>
                                <td>{log.vendor_inventory}</td>
                                <td>{log.vendor_sales}</td>
                                <td>{log.add_product}</td>
                                <td>{log.config_id_code}</td>
                                <td>{log.price_range}</td>
                                <td>{log.last_price}</td>
                                <td>{log.last_price_ID}</td>
                                <td>{log.last_profit}</td>
                                <td>{log.design_id}</td>
                                <td>{log.name}</td>
                                <td>{log.config_sku}</td>
                                <td>{log.collection_name}</td>
                                <td>{log.manufacturer}</td>
                                <td>{log.weave}</td>
                                <td>{log.country_of_manufacture}</td>
                                <td>{log.material}</td>
                                <td>{log.vendor_name}</td>
                                <td>{log.vendor_full_name}</td>
                                <td>{log.shippingh}</td>
                                <td>{log.shippingl}</td>
                                <td>{log.shippingw}</td>
                                <td>{log.shippingmethod}</td>
                                <td>{log.weight}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <div>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            


            <Pagination 
                currentPage={currentPage} 
                totalPages={totalPages} 
                onPageChange={handlePageChange} 
            />
      
        </div>


            </div>

        </EmployeeBodyComponents>
    )

}

export default EMVendorReportScreen



