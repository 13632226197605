import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByPriceComponets from './EMSalesReportByPriceComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByPriceScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Price"} searchTitre ={'Search by Price'} itemForChart={"sku__last_price"} categoryFilttering={'sku__last_price'}>

<EMSalesReportByPriceComponets />

</ReportFunction>

    );
};

export default EMSalesReportByPriceScreen;