const BASE_LINK = 'https://api.rlinternaltoolshub.ir/api/v1/';
// const BASE_LINK = 'http://127.0.0.1:8000/api/v1/';
export const MEDIA_LINK = 'https://api.rlinternaltoolshub.ir';

export const GET_REPORT_PAGE1 = `${BASE_LINK}reports/page1`;
export const GET_REPORT_SELLREPORT = `${BASE_LINK}reports/sells_view`;
// export const GET_REPORT_SELLREPORT_BY_COLOR = `${BASE_LINK}reports/sales_by_color_view`;
export const GET_REPORT_SELLREPORT_BY_CATEGORY = `${BASE_LINK}reports/sales_by_category_view`;
// export const GET_REPORT_SELLREPORT_BY_VENDOR_NAME = `${BASE_LINK}reports/sales_by_vendor_name_view`;

// export const MEDIA_LINK_IMAGE = 'https://api.moarefiman.ir';
export const GET_GUEST_HOME_PRODUCTLIST_SHOW_ALL = `${BASE_LINK}`;
export const GET_GUEST_HOME_PRODUCTLIST_DETAILS = `${BASE_LINK}product/`;

export const GET_GUEST_HOME_PRODUCTLIST_DETAILS_DETAILS = `${BASE_LINK}product/details/`;

export const GET_LOGIN_USER = `${BASE_LINK}maine/users/login/`;
export const GET_LINK_HOME_CARD_EMPLOYE = `${BASE_LINK}employee/`;
export const GET_LINK_EMPLOYEE_CLEANDATA_VENDOR_INFO = `${BASE_LINK}employee/vendor/cleandata/vendorinfo/`;
export const GET_LINK_EMPLOYEE_CLEANDATA_SHOW_ALL = `${BASE_LINK}employee/vendor/cleandata/show_all/`;
export const GET_LINK_EMPLOYEE_CLEANDATA_SHOW_DETAILS = `${BASE_LINK}employee/vendor/cleandata/show_details/`;


export const GET_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE = `${BASE_LINK}employee/vendor/cleandata/worke_space/`;
export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_CREATE = `${BASE_LINK}employee/vendor/cleandata/create_work_space`;

export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP1 = `${BASE_LINK}employee/vendor/cleandata/worke_space/step1`;

export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP2_DOWNLOADEMPTY = `${BASE_LINK}employee/vendor/cleandata/worke_space/step2/downloadempty`;
export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_DOWNLOADEMPTY = `${BASE_LINK}employee/vendor/cleandata/worke_space/step2/download_incomplete`;
export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_UPLOAD = `${BASE_LINK}employee/vendor/cleandata/worke_space/step2/upload_incomplete`;
export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_RECEIVE_FULL_FILE = `${BASE_LINK}employee/vendor/cleandata/worke_space/step2/receive_empty_cell`;
// jadid ezafeh shod

export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP2_HANDEL_IMAGE = `${BASE_LINK}employee/vendor/cleandata/worke_space/step2/handle_image`;


export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP3_DOWNLOADEMPTY = `${BASE_LINK}employee/vendor/cleandata/worke_space/step3/downloadempty`;
export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP3_DOWNLOADEMPTY = `${BASE_LINK}employee/vendor/cleandata/worke_space/step3/download_incomplete`;
export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP3_UPLOAD = `${BASE_LINK}employee/vendor/cleandata/worke_space/step3/upload_incomplete`;
export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP3_RECEIVE_MAPPING_FULL_FILE = `${BASE_LINK}employee/vendor/cleandata/worke_space/step3/receive_mapping`;


export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_INCOMPLETE_STEP4_RESET_DOCUMENT = `${BASE_LINK}employee/vendor/cleandata/worke_space/step4/reset_document`;


export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP4_DOWNLOAD_FINAL_FILE = `${BASE_LINK}employee/vendor/cleandata/worke_space/step4/download_final_file`;
export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_STEP4_RECAVE_FINAL_FILE = `${BASE_LINK}employee/vendor/cleandata/worke_space/step4/recave_final_file`;

export const POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_DOWNLOAD_ORGINAL_FILE = `${BASE_LINK}employee/vendor/cleandata/worke_space/download_orginal_file`;



export const LINK_PROFIT_CALCULATOR_STEP1 = `${BASE_LINK}profit/step1`;
export const LINK_PROFIT_CALCULATOR_STEP2 = `${BASE_LINK}profit/step2`;