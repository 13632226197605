import React from 'react'
import MaineBodyComponents from './MaineBodyComponents'
import { Row, Col } from 'react-bootstrap'
import LoaderComponents from './LoaderComponents'
import Message from './Message'

function MaineFormBodyComponents({ children,loading,errorMessage,variant }) {
  
  return (

    <MaineBodyComponents>
      {loading && <LoaderComponents />}
      {errorMessage && <Message variant={variant} text={errorMessage} />}

      <Row className='justify-content-md-center mt-5 pt-5'>
        <Col xs={12} md={6} className="p-5" style={{
          backgroundColor: '#FAF0E6',
          borderRadius: '10px'
        }}
        >
          {children}
        </Col>
      </Row>
    </MaineBodyComponents>



  )
}

export default MaineFormBodyComponents