import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function EmVendorCleanDataDocComponents({ docInfo }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortBy, setSortBy] = useState('ghesmat');
    console.log(docInfo)

    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState(null);
    const searchdocInfo = () => {
        const uniqueIds = new Set();
        return docInfo.filter(item => {
            if (uniqueIds.has(item.id)) {
                return false; // Skip duplicate entry
            }
            uniqueIds.add(item.id);
            return Object.entries(item).some(([key, value]) => {
                if (value === null || value === undefined) {
                    return false;
                }
                // تبدیل مقدار و searchTerm به حروف کوچک برای مقایسه
                return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
            });
        });
    };
    // const searchdocInfo = () => {
    //     const uniqueIds = new Set();
    //     return docInfo.filter(item => {
    //        if (uniqueIds.has(item.id)) {
    //            return false; // Skip duplicate entry
    //        }
    //        uniqueIds.add(item.id);
    //        return Object.entries(item).some(([key, value]) => {
    //            if (value === null || value === undefined) {
    //                return false;
    //            }
    //            return value.toString().includes(searchTerm);
    //        });
    //    });
    // };
    

    const handleEdit = (id) => {
        setSelectedId(id);
        // Code for handling edit functionality
    };

    const sortedData = searchdocInfo().sort((a, b) => {
        const sortOrderFactor = (sortOrder === 'asc') ? 1 : -1;
        return a[sortBy] > b[sortBy] ? sortOrderFactor : -sortOrderFactor;
    });

    const handleSort = (key) => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setSortBy(key);
    };

    const handelShowDoc = async (id) => {
        navigate(`/employee/vendor/cleandata/workspace/${id}`);
    };

    return (
        <>
            <div>
                <Row>
                    <Col>
                        <input
                            type="text"
                            placeholder="Search ..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                                padding: '5px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                margin: '10px',
                                width: '100%',
                            }}></input>
                    </Col>
                </Row>
            </div>

            <div>
                <Table striped bordered hover className={"text-center"}>
                    <colgroup>
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '30%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '10%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('ghesmat')}>Document ID</th>
                            <th onClick={() => handleSort('vendor_name')}> Vendor</th>
                            <th onClick={() => handleSort('status')}>Status</th>
                            <th onClick={() => handleSort('create_date')}>Create date</th>
                            <th>Show</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((docInfos, index) => (
                            <tr key={docInfos.id}>
                                <td>{docInfos.id}</td>
                                <td>{docInfos.vendor_name}</td>
                                <td>{docInfos.status}</td>
                                <td>{docInfos.create_date}</td>
                                <td>
                                    <Button
                                        onClick={() => handelShowDoc(docInfos.id)}
                                        variant="success">Show</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default EmVendorCleanDataDocComponents