import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByShippinghComponets from './EMSalesReportByShippinghComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByShippinghScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Shipping H"} searchTitre ={'Search by Shipping H'} itemForChart={"sku__shippingh"} categoryFilttering={'sku__shippingh'}>

<EMSalesReportByShippinghComponets />

</ReportFunction>

    );
};

export default EMSalesReportByShippinghScreen;