import React from 'react'
import VendorBodyComponents from './VendorBodyComponents'
import { Link } from 'react-router-dom'

function VendorHomeScreen() {
  return (
    <VendorBodyComponents>

      <h1 className='p-2'>
        Wellcome To VENDOR Page

      </h1>
      <h2 className='py-4'>

        Here you can achieve your dream
      </h2>

      <h1>
        Welcome to VENDOR page
 </h1>
    </VendorBodyComponents>
  )
}

export default VendorHomeScreen