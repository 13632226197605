import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByShippingmethodComponets from './EMSalesReportByShippingmethodComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByShippingmethodScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Shipping Method"} searchTitre ={'Search by Shipping Method'} itemForChart={"sku__shippingmethod"} categoryFilttering={'sku__shippingmethod'}>

<EMSalesReportByShippingmethodComponets />

</ReportFunction>

    );
};

export default EMSalesReportByShippingmethodScreen;