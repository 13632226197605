import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
// import AddInternaldocInfo from './AddInternaldocInfo';
import { Row, Col, Card, Container } from 'react-bootstrap';
// import EditInternalModal from './EditInternalModal';
import { GET_LINK_EMPLOYEE_CLEANDATA_SHOW_ALL } from '../../mainesrc/AllLinkConst';
import EmployeeBodyComponents from '../EmployeeBodyComponents';
import EmVendorCleanDataDocComponents from './EmVendorCleanDataDocComponents';
import CheckoutSteps from '../../mainesrc/CheckoutSteps';

function EmVendorCleanDataDocScreen() {
  const [docInfo, setDocInfo] = useState([]);
  const [serrorMessage, setErrorMessage] = useState(null);






  useEffect(() => {
    const fetchCompanys = async () => {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = userInfo.token;

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Fix the typo 'Bearera' to 'Bearer'
          }
        };

        const { data } = await axios.get(GET_LINK_EMPLOYEE_CLEANDATA_SHOW_ALL, config);
        
        
        const updatedData = data.map(item => { 
          const createDate = new Date(item.create_date);
          const formattedDate = createDate.toISOString().split('T')[0];
          return { ...item, create_date: formattedDate };
      });
      setDocInfo(updatedData);
      


      } catch (error) {
        const errorMessage = error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message;

        setErrorMessage(errorMessage);
      }
    };

    fetchCompanys();

  }, []);





  return (

    <EmployeeBodyComponents>
<Container fluid>


      <CheckoutSteps step1="Vendor" step1Link='/employee/vendor' />
      <h4 >
        All  Document Clean Data
      </h4>

      <  EmVendorCleanDataDocComponents docInfo={docInfo} />

</Container>

    </EmployeeBodyComponents>
  )
}

export default EmVendorCleanDataDocScreen