import React from 'react';
import { Spinner } from 'react-bootstrap';

function LoaderComponents() {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999
            }}
        >
            <Spinner animation="border" variant="info" role='status'
                style={{
                    height: '100px',
                    width: '100px'
                }}
            />
         
        </div>
    );
}

export default LoaderComponents;