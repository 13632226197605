import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByColorComponets from './EMSalesReportByColorComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByColorScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Color"} searchTitre ={'Search by Color'} itemForChart={"sku__color"} categoryFilttering={"sku__color"}>

<EMSalesReportByColorComponets />

</ReportFunction>

    );
};

export default EMSalesReportByColorScreen;