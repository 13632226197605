import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByShippinglComponets from './EMSalesReportByShippinglComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByShippinglScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Shipping L"} searchTitre ={'Search by Shipping L'} itemForChart={"sku__shippingl"} categoryFilttering={'sku__shippingl'}>

<EMSalesReportByShippinglComponets />

</ReportFunction>

    );
};

export default EMSalesReportByShippinglScreen;