import React from 'react'
import { Link } from 'react-router-dom'

// import React from 'react';
import { useNavigate } from 'react-router-dom'; // استفاده از useNavigate برای هدایت به صفحه دیگر
import { GET_REPORT_SELLREPORT } from '../../mainesrc/AllLinkConst';

// import { Bar, Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, PointElement);




function EMSalesReportByColorComponets({salesData}) {
    const navigate = useNavigate();
  
    // تابع برای هدایت به صفحه دیگری با ارسال اطلاعات از جمله لینک و فیلترها
    const handleColorClick = (item) => {
        localStorage.setItem('query_need1', 'sku__color__iexact'); // داده‌ها را ذخیره کنید
        localStorage.setItem('query_need_food1', item.sku__color); // داده‌ها را ذخیره کنید
        navigate('/report/sellreport', {
            state: {
              link: GET_REPORT_SELLREPORT, // لینک گزارش
              titre: "Sells By SKU", // عنوان گزارش
              searchTitre: 'Search by SKU', // عنوان جستجو
              itemForChart: "sku", // آیتمی که برای چارت باید نمایش داده شود
              categoryFilttering: item.sku__color, // استفاده از رنگ برای فیلتر
            },
          });
      
  
        };

  return (
    <>
    <thead>
        <tr>
            <th>Color</th>
            <th>Total Sales</th>
            <th>Total Added Products</th>
            <th>Average Sales</th>
            <th>Variance Sales</th>
            <th>Max Sales</th>
            <th>Min Sales</th>
            <th>Count Products</th>
        </tr>
    </thead>
    <tbody>
    {Array.isArray(salesData) && salesData.length > 0 ? (

        salesData.map((item) => (
            <tr key={item.sku__color}>
              
                            <td onClick={() => handleColorClick(item)} style={{ cursor: 'pointer', color: 'blue' }}>
 
                {item.sku__color}
                
               
                </td>
                <td>{item.total_sales}</td>
                <td>{item.total_added_products}</td>
                <td>{Math.floor(item.average_sales)}</td>
                <td>{Math.floor(item.variance_sales)}</td>

                <td>{item.max_sales}</td>
                <td>{item.min_sales}</td>
                <td>{item.count_products}</td>
            </tr>
        ))

) : (
           
            <tr>< td colSpan={20}>No sales data available.</ td></tr>
        )}
    </tbody>


    
</>
  )






}

export default EMSalesReportByColorComponets




