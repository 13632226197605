import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// import { GET_REPORT_PAGE1 } from '../../mainesrc/AllLinkConst';
// import { GET_REPORT_PAGE1 } from '../../mainesrc/AllLinkConst';



function EMReportComponets({ salesData }) {
    const navigate = useNavigate();
  
    const [startDate, setStartDate] = useState(() => localStorage.getItem('startDate') || ''); // مقدار پیش‌فرض از localStorage
       const [endDate, setEndDate] = useState(() => localStorage.getItem('endDate') || ''); // مقدار پیش‌فرض از localStorage







    // const location = useLocation();
    // const queryNeedFood1 = location.state?.query_need_food1 || ''; // مقدار query_need_food1 از location

    // const [changeLogs, setChangeLogs] = useState([]);
    // const [errorMessage, setErrorMessage] = useState(null);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [totalPages, setTotalPages] = useState(0);
    // const pageSize = 100;
    // // تابع برای بارگذاری داده‌ها
    // const fetchChangeLogs = async (page) => {
    //     try {
    //         const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //         const token = userInfo?.token;

    //         const config = {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             params: {
    //                 page,
    //                 page_size: pageSize,
    //                 query_need_food: queryNeedFood1, // استفاده از مقدار دریافتی

             
    //             },
    //         };

    //         const { data } = await axios.get(GET_REPORT_PAGE1, config);
    //         console.log(data);
    //         setChangeLogs(data.results); // داده‌های paginated
    //         setTotalPages(Math.ceil(data.count / pageSize)); // محاسبه تعداد کل صفحات
    //     } catch (error) {
    //         const errorMessage = error.response && error.response.data.detail
    //             ? error.response.data.detail
    //             : error.message;
    //         setErrorMessage(errorMessage);
    //     }
    // };

    // // بارگذاری داده‌ها در mount
    // useEffect(() => {
    //     fetchChangeLogs(currentPage);
    // }, [currentPage, queryNeedFood1]);


    useEffect(() => {
        const savedStartDate = localStorage.getItem('startDate');
        const savedEndDate = localStorage.getItem('endDate');
        // const savedSearchInput = localStorage.getItem('searchInput');
        // const savedSelectedOptions = localStorage.getItem('selectedOptions');

        if (savedStartDate) {
            setStartDate(savedStartDate);
        }

        if (savedEndDate) {
            setEndDate(savedEndDate);
        }

        // if (savedSearchInput) {
        //   setSearchInput(savedSearchInput);
        // }


    }, []); // این effect فقط یک بار اجرا می‌شود هنگام بارگذاری کامپوننت















    // تابع برای هدایت به صفحه دیگری با ارسال اطلاعات از جمله لینک و فیلترها
    const handleColorClick = (item) => {
        navigate('/report', {
            state: {
                query_need_food1: item.sku, // ارسال مقدار query_need_food1

                start_date: startDate,
                end_date: endDate,
            },
        });
    };
    
    return (

        <>


            <thead>
                <tr>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Total Sales</th>
                    <th>Total Added Products</th>
                    <th>Average Sales</th>
                    <th>Variance Sales</th>
                    <th>Max Sales</th>
                    <th>Min Sales</th>
                    <th>Count Products</th>
                    <th>UPC</th>
                    <th>category</th>
                    <th>material</th>
                    <th>size</th>
                    <th>free_rugpad</th>
                    <th>shape</th>
                    <th>color</th>
                    <th>color_filter</th>
                    <th>active</th>

                    <th>Real Time Price ID</th>
                    <th>Real Time Price </th>
                    <th>Real Time Profit</th>


                    <th>Vendor Inventory</th>
                    <th>Vendor Sales</th>
                    <th>Add Product</th>
                    <th>Create Date</th>
                    <th>Config ID Code</th>
                    <th>Price Range</th>
                    <th>Last Price</th>
                    <th>Last Price ID</th>
                    <th>Last Profit</th>
                    <th>design_id</th>
                    <th>Config name</th>
                    <th>Config Sku</th>
                    <th>collection_name</th>
                    <th>manufacturer</th>
                    <th>weave</th>
                    <th>country_of_manufacture</th>
                    <th>material</th>
                    <th>vendor_name name</th>
                    <th>fullName</th>
                    <th>shippingh</th>
                    <th>shippingl</th>
                    <th>shippingw</th>
                    <th>shippingmethod</th>
                    <th>weight</th>

                </tr>
            </thead>
            <tbody>
                {Array.isArray(salesData) && salesData.length > 0 ? (
                    salesData.map((item) => (
                        <tr key={item.sku}>
                            <td  
                            onClick={() => handleColorClick(item)} style={{ cursor: 'pointer', color: 'blue' }}
                            
                            >{item.sku}</td>
                            <td>{item.name}</td>
                            <td>{item.total_sales}</td>
                            <td>{item.total_added_products}</td>
                            <td>{Math.floor(item.average_sales)}</td>
                            <td>{Math.floor(item.variance_sales)}</td>
    
                            <td>{item.max_sales}</td>
                            <td>{item.min_sales}</td>
                            <td>{item.count_products}</td>
                            <td>{item.upc}</td>
                            <td>{item.category}</td>
                            <td>{item.material}</td>
                            <td>{item.size}</td>
                            <td>{item.free_rugpad}</td>
                            <td>{item.shape}</td>
                            <td>{item.color}</td>
                            <td>{item.color_filter}</td>
                            <td>{item.active ? 'Yes' : 'No'}</td>
                            <td>{item.real_time_price_id}</td>
                            <td>{item.real_time_price}</td>
                            <td>{item.real_time_profit}</td>
                            <td>{item.vendor_inventory}</td>
                            <td>{item.vendor_sales}</td>
                            <td>{item.add_product}</td>
                            <td>{new Date(item.crearte_in_db).toLocaleString()}</td>
                            <td>{item.config_id_code}</td>
                            <td>{item.price_range}</td>
                            <td>{item.last_price}</td>
                            <td>{item.last_price_ID}</td>
                            <td>{item.last_profit}</td>
                            <td>{item.design_id}</td>
                            <td>{item.name}</td> {/* اینجا ممکن است تکراری باشد */}
                            <td>{item.config_sku}</td>
                            <td>{item.collection_name}</td>
                            <td>{item.manufacturer}</td>
                            <td>{item.weave}</td>
                            <td>{item.country_of_manufacture}</td>
                            <td>{item.material}</td> {/* اینجا ممکن است تکراری باشد */}
                            <td>{item.vendor_name}</td>
                            <td>{item.vendor_full_name}</td>
                            <td>{item.shippingh}</td> {/* نام این فیلدها را بررسی کنید */}
                            <td>{item.shippingl}</td>
                            <td>{item.shippingw}</td>
                            <td>{item.shippingmethod}</td>
                            <td>{item.weight}</td>
                        </tr>
                    ))

                ) : (
                    // اگر هیچ داده‌ای وجود نداشته باشد
                    <tr>< td colSpan={20}>No sales data available.</ td></tr>
                )}
            </tbody>


        </>
    )
}

export default EMReportComponets