import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByDesignerComponets from './EMSalesReportByDesignerComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByDesignerScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Designer"} searchTitre ={'Search by Designer'} itemForChart={"sku__config_info__designer"} categoryFilttering={'sku__config_info__designer'}>

<EMSalesReportByDesignerComponets />

</ReportFunction>

    );
};

export default EMSalesReportByDesignerScreen;