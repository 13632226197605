import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByDepartmentComponets from './EMSalesReportByDepartmentComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByDepartmentScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Department"} searchTitre ={'Search by Department'} itemForChart={"sku__department"} categoryFilttering={'sku__department'}>

<EMSalesReportByDepartmentComponets />

</ReportFunction>

    );
};

export default EMSalesReportByDepartmentScreen;