import React, { useState } from 'react'
import MaineFormBodyComponents from './MaineFormBodyComponents'
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { GET_LOGIN_USER } from './AllLinkConst';
import { Button, Col, Form, Row } from 'react-bootstrap';

function LoginScreen() {
  const [loader, setLoader] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");



  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const [userInfo, setUserInfo] = useState("");

  const navigate = useNavigate();


  const handelLogin = async (e) => {
    e.preventDefault();

    setLoader(true)
    setErrorMessage("")
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(GET_LOGIN_USER, { username: username, password: password }, config);
      localStorage.setItem("userInfo", JSON.stringify(data));
      localStorage.setItem("loggedIn", "true");
      
      setUserInfo(JSON.stringify(data))
      

      setLoader(false)


      if (userInfo || localStorage.getItem('userInfo')) {
        if (data.isCustomer) {
          navigate("/customer")       
        }else if (data.isEmployee) {      
          navigate("/employee")
        } else if (data.isVendor){         
          navigate("/vendor")        
        }
      }

    } catch (error) {
      localStorage.removeItem("userInfo");
      setLoader(false)


      error.response && error.response.data.detail
        ? setErrorMessage(error.response.data.detail)
        : setErrorMessage(error.message)
        ;
    }
  }

  return (
    <MaineFormBodyComponents loading={loader} errorMessage={errorMessage} variant='danger'>
      <Form onSubmit={handelLogin} >
        <Form.Group controlId="email">
          <Form.Label> User Name  </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your User name"
            value={username}
            onChange={(e) => setusername(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password" className="py-4">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button type="submit" variant="primary" className="col-4">Login</Button>
      </Form>

      <Row className="my-4" >
        <Col>
          <Link to="/" style={{ textDecoration: 'none' }} hidden>
            ثبت نام کاربر جدید
          </Link>
        </Col>
      </Row>

    </MaineFormBodyComponents>
  )
}

export default LoginScreen


