import React, { useEffect, useState } from 'react'
import GuestBodyComponents from '../GuestBodyComponents'
import { GET_GUEST_HOME_PRODUCTLIST_DETAILS } from '../../mainesrc/AllLinkConst';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';

function ProductDetailsScreen() {

    const [product, setProduct] = useState([])
    // const [docInfo, setDocInfo] = useState([]);
    const [serrorMessage, setErrorMessage] = useState(null);
    const { pk } = useParams();
  
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`
        }
    };
  
  
  
  
    useEffect(() => {
        const fetchCompanys = async () => {
            try {


                const { data } = await axios.get(`${GET_GUEST_HOME_PRODUCTLIST_DETAILS}${pk}`, config);
                // const updatedData = data.map(item => {
                //     const createDate = new Date(item.create_date);
                //     const formattedDate = createDate.toISOString().split('T')[0];
                //     return { ...item, create_date: formattedDate };
                setProduct(data)
                console.log(data)
                // });
                // setProduct(updatedData);

            } catch (error) {
                const errorMessage = error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message;

                setErrorMessage(errorMessage);
            }
        };

        fetchCompanys();

    }, [pk]);


  return (

<GuestBodyComponents>
<div>
            <h3>
                Produc
            </h3>

            <Row>
                {product.map(product => (
                    <Col key={product.id} sm={12} md={6} lg={3} xl={3}>
                        

                        <div>
                      <Card className='p-3 my-3 rounded' style={{
                        height: '350px',
                        alignItems: 'center'


      }}>
         
                        <Link to={`/product/details/${product.id}`}>
          {/* <Card.Img src={product.image} className='card-img-top' /> */}
                          <Card.Img
                          src={require("./logo.png")}
                          //  src={product.photo}
                            className='card-img-top' style={{
            height:'300px'
          }} />
        </Link>
        <Card.Body>
                          <Link to={`/product/details/${product.id}`}>
            <br />

            {product.manufacturer}
            {/* {product.id} */}
            <Card.Title as="h5">
              <strong style={{color: "black" }}>{product.name}</strong>
            </Card.Title>
            {/* <br />
            <Card.Text as="div" className='my-3'>
            {product.sku}
            <br />
            {product.upc}
              {/* {product.rating} from {product.numReviews} Reviews */}
              {/* <Rating value={product.rating} text={`مشاهده شده: ${product.numReviews}`} color={"#F8E825"} /> */}
            {/* </Card.Text> */} 

          </Link>
        </Card.Body>
      </Card>
    </div>
                        
                    </Col>
                ))}
            </Row>
        </div>
</GuestBodyComponents>

  )
}

export default ProductDetailsScreen