import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByCollectionNameComponets from './EMSalesReportByCollectionNameComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByCollectionNameScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Collection Name"} searchTitre ={'Search by Collection Name'} itemForChart={"sku__config_info__collection_name"} categoryFilttering={'sku__config_info__collection_name'}>

<EMSalesReportByCollectionNameComponets />

</ReportFunction>

    );
};

export default EMSalesReportByCollectionNameScreen;