import React from 'react';
import ReportFunction from './ReportFunction';
import EMReportComponets from './EMReportComponets';
import { GET_REPORT_SELLREPORT } from '../../mainesrc/AllLinkConst';

const EMSalesReportScreen = ({salesData}) => {

    return (
<ReportFunction  link={GET_REPORT_SELLREPORT}  titre={"Sells By SKU"} searchTitre ={'Search by SKU'} itemForChart={"sku"}>
    <EMReportComponets />
</ReportFunction>
    );
};

export default EMSalesReportScreen;