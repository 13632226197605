import React, { useEffect, useState } from 'react'

import EmployeeBodyComponents from '../EmployeeBodyComponents'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { GET_LINK_EMPLOYEE_CLEANDATA_SHOW_DETAILS, GET_LINK_EMPLOYEE_CLEANDATA_VENDOR_INFO, MEDIA_LINK, POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_CREATE } from '../../mainesrc/AllLinkConst';
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import EmVendorCleanDataDocComponents from './EmVendorCleanDataDocComponents';



function EmVendorCleanDataDetailsScreen() {
    const [vendorDetails, setVendorDetails] = useState([]);
    const { pk } = useParams();
    const [errorMessage, setErrorMessage] = useState([]);
    const [docInfo, setDocInfo] = useState([]);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const token = userInfo.token;
    const navigate = useNavigate();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };

    const handelAddNew = async () => {
        // console.log(config)
        try {


            const { data } = await axios.post(POST_LINK_EMPLOYEE_CLEANDATA_WORKE_SPACE_CREATE, { vendor_id: pk, total_rows: 100 }, config);
            setVendorDetails(data);
            console.log(pk)
            navigate(`/employee/vendor/cleandata/workspace/${data.id}`)


        } catch (error) {
            const errorMessage = error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message;

            setErrorMessage(errorMessage);
            // console.log(errorMessage)
        }
    }



    useEffect(() => {
        const fetchCompanys = async () => {
            try {


                const { data } = await axios.get(`${GET_LINK_EMPLOYEE_CLEANDATA_VENDOR_INFO}${pk}`, config);
                setVendorDetails(data);


            } catch (error) {
                const errorMessage = error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message;

                setErrorMessage(errorMessage);
            }
        };

        fetchCompanys();

    }, [pk]);


    useEffect(() => {
        const fetchCompanys = async () => {
            try {


                const { data } = await axios.get(`${GET_LINK_EMPLOYEE_CLEANDATA_SHOW_DETAILS}${pk}`, config);
                const updatedData = data.map(item => {
                    const createDate = new Date(item.create_date);
                    const formattedDate = createDate.toISOString().split('T')[0];
                    return { ...item, create_date: formattedDate };
                });
                setDocInfo(updatedData);

            } catch (error) {
                const errorMessage = error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message;

                setErrorMessage(errorMessage);
            }
        };

        fetchCompanys();

    }, [pk]);


    return (


        <EmployeeBodyComponents>
<Container fluid>


            {vendorDetails && vendorDetails.vendor_name && (
                <Row className='pt-3'>
                    <Col>
                        <Row>
                            <Col lg={3}>
                                <img src={`${MEDIA_LINK}${vendorDetails.vendor_name.photo}`} alt={vendorDetails.vendor_name.brand_name} style={{ width: '200px', height: '200px', alignContent: 'center' }} />
                            </Col>
                            <Col>
                                <h2 >
                                    {vendorDetails.vendor_name.brand_name}
                                </h2>
                                <h4>
                                    {vendorDetails.vendor_name.fullName}
                                </h4>
                                <p>
                                    {vendorDetails.vendor_name.description}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} className='py-2'>
                        <Button onClick={handelAddNew}>
                            Add New Document
                        </Button>
                        <Col className='pt-2' lg={12}>
                        </Col>
                        <EmVendorCleanDataDocComponents docInfo={docInfo} />
                    </Col>

                </Row>
            )}


</Container>


        </EmployeeBodyComponents>

    )
}

export default EmVendorCleanDataDetailsScreen