// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/fonts/Vazir.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../public/fonts/Vazir.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../public/fonts/Vazir.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../../public/fonts/Vazir.eot", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Vazir';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff'),
         url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('ttf'),
         url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('eot');
    font-weight: 400;
    font-style: normal;
  }`, "",{"version":3,"sources":["webpack://./src/assets/statics/css/fonts.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB;;;8DAG4D;IAC5D,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":["@font-face {\r\n    font-family: 'Vazir';\r\n    src: url('../../../../public/fonts/Vazir.woff2') format('woff2'),\r\n         url('../../../../public/fonts/Vazir.woff') format('woff'),\r\n         url('../../../../public/fonts/Vazir.ttf') format('ttf'),\r\n         url('../../../../public/fonts/Vazir.eot') format('eot');\r\n    font-weight: 400;\r\n    font-style: normal;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
