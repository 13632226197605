import EmployeeBodyComponents from '../EmployeeBodyComponents'
import React, { useRef, useState } from 'react'
import axios from 'axios';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import {
    LINK_PROFIT_CALCULATOR_STEP1,
    LINK_PROFIT_CALCULATOR_STEP2,
} from '../../mainesrc/AllLinkConst';
import LoaderComponents from '../../mainesrc/LoaderComponents';
import Message from '../../mainesrc/Message';


function ProfitCalculator() {
    const [errorMessage, setErrorMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [documetId, setDocumetId] = useState();
    const [status, setStatus] = useState("");
    // const formRef = useRef(null);
    const [selectedFileIncomplitedStep2, setSelectedFileIncomplitedStep2] = useState(null);
    const handleClickDownloadIncomplateFileStep2 = (e) => {
        e.preventDefault();
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = userInfo.token;
        // formRef.current.reset();
        const config = {
            responseType: 'blob',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        axios.post(LINK_PROFIT_CALCULATOR_STEP2, { 'document_id': documetId }, config
        ).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'final_profit.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            console.error('Error downloading file:', error);
        });
    };
    const handleUploadChangeInComplitedStep2 = async (e) => {
        // formRef.current.reset();
        e.preventDefault();
        setLoading(true);
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = userInfo.token;
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const formData = new FormData();
        formData.append('file', selectedFileIncomplitedStep2);
        // formData.append('selectedRadio', vendorName);
        // formData.append('document_id', documetId);
        try {
            const response = await axios.post(LINK_PROFIT_CALCULATOR_STEP1, formData, config);
            const jsonData = response.data.data;
            console.log(jsonData);
            setLoading(false)
            setStatus(jsonData)
            // setStep1(false)
            // setStep2(true)
            // formRef.current.reset();
     
        } catch (error) {
            setLoading(false);
            const errorMessage = error.response ? error.response.data.error : 'An error occurred while uploading file.';
            setErrorMessage(errorMessage);
        }
    };

    const handleFileChangeInComplitedStep1 = (e) => {
        setSelectedFileIncomplitedStep2(e.target.files[0]);
    };

    return (

        <EmployeeBodyComponents>
            <Container fluid>

            {loading && <LoaderComponents />}
            {errorMessage && errorMessage.length > 0 && <Message variant='info' text={errorMessage} />}
            {status && status.length > 0 && <Message variant='info' text={status} />}
            {/* <Message variant='info' text={"Milad"} /> */}
                <Row>
                    <Col lg={12} className='p-2' >
                        <h4>
                            Profit Calculator
                        </h4>
                        <div
                        >
                            <Form
                            // onSubmit={handleSubmit}
                            // ref={formRef}
                            >
                                <Form.Group controlId="formFile">
                                    <Row className='g-2'>
                                        <Col sm={12}>
                                            <h2 >
                                                لطفا فایل اکسل قیمت خود را برای محاسبه پرافیت وارد کنید
                                            </h2>
                                        </Col>
                                        <Col md={2} className='d-flex justify-content-center' >

                                            <Button className='mx-2 w-100'
                                                onClick={handleClickDownloadIncomplateFileStep2}
                                            >
                                                دانلود فایل
                                            </Button>
                                        </Col>
                                        <Col md={2} >
                                            <Button className='w-100'
                                                onClick={handleUploadChangeInComplitedStep2}
                                            >
                                                آپلود فایل                      </Button>
                                        </Col>
                                        <Col md={8} >
                                            <Form.Control type="file"
                                                onChange={handleFileChangeInComplitedStep1}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </EmployeeBodyComponents>
    )
}
export default ProfitCalculator