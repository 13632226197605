import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByMaterialComponets from './EMSalesReportByMaterialComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByMaterialScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Material"} searchTitre ={'Search by Material'} itemForChart={"sku__material"} categoryFilttering={'sku__material'}>

<EMSalesReportByMaterialComponets />

</ReportFunction>

    );
};

export default EMSalesReportByMaterialScreen;