import React from 'react'
import { Alert } from 'react-bootstrap'

function Message({ variant, text }) {
    return (
        <div>
            <Alert variant={variant}>
                <h2>

                    {text}
                </h2>
            </Alert>

        </div>
    )
}

export default Message
