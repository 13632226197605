import React, { useEffect, useState } from 'react'
import GuestBodyComponents from '../GuestBodyComponents'
import { GET_GUEST_HOME_PRODUCTLIST_DETAILS, GET_GUEST_HOME_PRODUCTLIST_DETAILS_DETAILS } from '../../mainesrc/AllLinkConst';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';


// import GuestBodyComponents from '../GuestBodyComponents'
// GET_GUEST_HOME_PRODUCTLIST_DETAILS_DETAILS

function ProductDetailsDetailsScreen() {
  const [product, setProduct] = useState([])
  // const [docInfo, setDocInfo] = useState([]);
  const [serrorMessage, setErrorMessage] = useState(null);
  const { pk } = useParams();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`
    }
  };

  useEffect(() => {
    const fetchCompanys = async () => {
      try {


        const { data } = await axios.get(`${GET_GUEST_HOME_PRODUCTLIST_DETAILS_DETAILS}${pk}`, config);
        // const updatedData = data.map(item => {
        //     const createDate = new Date(item.create_date);
        //     const formattedDate = createDate.toISOString().split('T')[0];
        //     return { ...item, create_date: formattedDate };
        setProduct(data)
        console.log(data)
        // });
        // setProduct(updatedData);

      } catch (error) {
        const errorMessage = error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message;

        setErrorMessage(errorMessage);
      }
    };

    fetchCompanys();

  }, [pk]);


  return (
    <GuestBodyComponents>

   
      <Row className='p-5'>
        <Col>
          <Row>
            <Col sm={12} className='p-5'>
          <h2>

        {product.name}
          </h2>
           
            </Col>
            <Col sm={12}>
            
          <p>
            {product.category}
          </p>
            
            </Col>
            <Col 
            
            
            
            >
              <p>
           
                price: {product.color} $

              </p>
              
              <p className='p-2'>

              </p>
              <p>
            {product.sku} 

              </p>
              
          
                
                <Form
                // onSubmit={handelLogin}
                className='p-5'
                >
                  <Form.Group controlId="email" className='p-2'>
                    <Form.Label> Add to Card</Form.Label>
                    <Form.Control
                      type="number"
                      // placeholder="ورود نام کاربری"
                      // value={username}
                      // onChange={(e) => setusername(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  {/* <Form.Group controlId="password" className="py-4">
                    <Form.Label>پسورد</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="ورود پسورد"
                      value={password}
                      // onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group> */}
                  <Button   type="submit" variant="primary" className="w-50 p-2" >Add to cart </Button>
                </Form>
             
            </Col>
          </Row>
        </Col>
        <Col>
          <Card.Img 
                          src={require("./logo.png")}

          // src={product.photo} 
          className='card-img-top'  />
        </Col>
      </Row>

    </GuestBodyComponents>
  )
}

export default ProductDetailsDetailsScreen