import React from 'react'
import EmployeeBodyComponents from '../EmployeeBodyComponents'
import { Container } from 'react-bootstrap'


function EmVendorHomeScreen() {
  return (
      <EmployeeBodyComponents >
<Container fluid>


          <h1>
              Welcome to Vendor Page
          </h1>
</Container>
      </EmployeeBodyComponents>  

)
}

export default EmVendorHomeScreen