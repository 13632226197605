import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LogoutAction from '../../mainesrc/LogoutAction'


function EmContactsBodyComponents({ children }) {
  return (
    <>

    <header>

        <Navbar expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand>
                    <Nav.Link as={Link} to="/employee" >
                        Home
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">

                    <Nav.Link as={Link} to="##" >See All</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                        {/* <Nav.Link as={Link} to="/logout" >Logout</Nav.Link> */}
                        <LogoutAction />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header >

    <main className='pt-2'>
        <Container>


            {children}


        </Container>
    </main>

</>
  )
}

export default EmContactsBodyComponents


