import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportBySizeComponets from './EMSalesReportBySizeComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportBySizeScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Size"} searchTitre ={'Search by Size'} itemForChart={"sku__size"} categoryFilttering={'sku__size'}>

<EMSalesReportBySizeComponets />

</ReportFunction>

    );
};

export default EMSalesReportBySizeScreen;