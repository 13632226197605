import React from 'react';
import ReportFunction from './ReportFunction';
import EMSalesReportByCategoryComponets from './EMSalesReportByCategoryComponets';
import { GET_REPORT_SELLREPORT_BY_CATEGORY } from '../../mainesrc/AllLinkConst';

const EMSalesReportByCategoryScreen = ({salesData}) => {
 
    return (

<ReportFunction  link={GET_REPORT_SELLREPORT_BY_CATEGORY}  titre={"Sells By Category"} searchTitre ={'Search by Category'} itemForChart={"sku__category"} categoryFilttering={'sku__category'}>

<EMSalesReportByCategoryComponets />

</ReportFunction>

    );
};

export default EMSalesReportByCategoryScreen;